import CategoySlider from "components/CategoySlider";
import Heading from "components/Heading/Heading";
import { Category } from "framework/rest/types";
import { FC, ReactNode } from "react";

export interface SectionSliderNewCategories3Props {
    className?: string;
    heading: string;
    subHeading: string | ReactNode;
    categories: Category[];
    categoryCardType?: "card1" | "card2" | "card3" | "card4" | "card5";
    itemPerRow?: 4 | 5;
}

const SectionSliderNewCategories3: FC<SectionSliderNewCategories3Props> = ({
    heading,
    subHeading,
    className = "",
    categories,
    itemPerRow = 5,
    categoryCardType = "card3",
}) => {
    return (
        <div className={`nc-SectionSliderNewCategories3 ${className}`}>
            <Heading desc={subHeading}>{heading}</Heading>
            <CategoySlider data={categories || []} itemPerRow={itemPerRow} />
        </div>
    );
};

export default SectionSliderNewCategories3;
