import { FC } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

export interface PostCardCommentBtnProps {
    className?: string;
    isATagOnSingle?: boolean;
}

const PostCardCommentBtn: FC<PostCardCommentBtnProps> = ({
    className = 'flex px-3 h-8 text-xs',
    isATagOnSingle = false,
}) => {
    if (isATagOnSingle) {
        return (
            <a
                href={'#comments'}
                className={`nc-PostCardCommentBtn relative items-center min-w-[68px] rounded-full text-neutral-6000 bg-neutral-50 transition-colors dark:text-neutral-200 dark:bg-neutral-800 hover:bg-teal-50 dark:hover:bg-teal-100 hover:text-teal-600 dark:hover:text-teal-500 ${className} `}
                title="Comments"
            >
                <IoEyeOutline size={20} />

                <span className="ml-1 text-neutral-900 dark:text-neutral-200">{'110'}</span>
            </a>
        );
    }

    return (
        <Link
            to={'#'}
            className={`nc-PostCardCommentBtn relative items-center min-w-[68px] rounded-full text-neutral-6000 bg-neutral-50 transition-colors dark:text-neutral-200 dark:bg-neutral-800 hover:bg-teal-50 dark:hover:bg-teal-100 hover:text-teal-600 dark:hover:text-teal-500 ${className} `}
            title="Comments"
        >
            <IoEyeOutline size={20} />

            <span className="ml-1 text-neutral-900 dark:text-neutral-200">{'110'}</span>
        </Link>
    );
};

export default PostCardCommentBtn;
