import NcDropDown from 'components/NcDropDown/NcDropDown';
import { SOCIALS_DATA } from 'components/SocialsShare/SocialsShare';
import { PostDataType } from 'data/types';
import { FC } from 'react';
import { toast } from 'react-toastify';

export interface PostCardSaveActionProps {
    className?: string;
    bookmarkClass?: string;
    readingTime?: number;
    hidenReadingTime?: boolean;
    post?: PostDataType;
}

const PostCardSaveAction: FC<PostCardSaveActionProps> = ({
    className = '',
    bookmarkClass,
    hidenReadingTime = true,
    readingTime = 3,
    post,
}) => {
    return (
        <div
            className={`nc-PostCardSaveAction flex items-center space-x-2 text-xs text-neutral-700 dark:text-neutral-300 ${className}`}
        >
            {!hidenReadingTime && !!readingTime && <span>{readingTime} min read</span>}

            <NcDropDown
                className="flex-shrink-0 flex items-center justify-center focus:outline-none h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 text-neutral-700 dark:text-neutral-200 rounded-full"
                renderTrigger={() => (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                        />
                    </svg>
                )}
                onClick={item => {
                    let url = '#';

                    if (post?.type === 'textual') {
                        url = `${process.env.REACT_APP_URL}/post-textual/${post?.id}`;
                    } else if (post?.type === 'photo') {
                        url = `${process.env.REACT_APP_URL}/post-photo/${post?.id}`;
                    } else if (post?.type === 'video') {
                        url = `${process.env.REACT_APP_URL}/post-video/${post?.id}`;
                    } else {
                        url = `${process.env.REACT_APP_URL}${post?.href}`;
                    }

                    if (item.url !== '#') {
                        let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;

                        window.open(
                            item
                                ?.url!.replace('{{url}}', encodeURIComponent(url))
                                .replace('{{title}}', post?.post_title! || post?.title!),
                            '_blank',
                            params,
                        );
                    } else {
                        navigator.clipboard.writeText(url);
                        toast.success('Link Kopyalandı', {
                            autoClose: 1000,
                            position: 'top-center',
                        });
                        return;
                    }
                }}
                data={SOCIALS_DATA}
            />
        </div>
    );
};

export default PostCardSaveAction;
