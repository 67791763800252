import React, { FC } from "react";
import NcModal from "components/NcModal/NcModal";
import Tag from "components/Tag/Tag";
import { TaxonomyType } from "data/types";
import Button from "components/Button/Button";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useTagsQuery } from "framework/rest/tags/tag.query";

export interface ModalTagsProps {
  tags: TaxonomyType[];
}

const ModalTags: FC<ModalTagsProps> = ({ tags }) => {
  const { data, isLoading } = useTagsQuery({
    limit: 9,
  });

  if (isLoading) return <div>Loading...</div>;


  const renderModalContent = () => {
    return (
      <div className="flex flex-wrap dark:text-neutral-200">
         {data?.tags.data.map((tag) => (
          <Tag className="mr-2 mb-2" key={tag.id} tag={tag} />
        ))}
      </div>
    );
  };

  return (
    <div className="nc-ModalTags">
      <NcModal
        contentExtraClass="max-w-screen-md"
        renderTrigger={(openModal) => (
          <Button
            pattern="third"
            fontSize="text-sm font-medium"
            onClick={openModal}
          >
            <div>
              <span className="hidden sm:inline"> Etiketler </span>
            </div>
            <ChevronDownIcon
              className="w-4 h-4 ml-2 -mr-1"
              aria-hidden="true"
            />
          </Button>
        )}
        modalTitle="Tüm Etiketler"
        renderContent={renderModalContent}
      />
    </div>
  );
};

export default ModalTags;
