import Avatar from "components/Avatar/Avatar";
import { PostDataType } from "data/types";
import { FC } from "react";

export interface SingleAuthorProps {
    author: PostDataType;
}

const SingleAuthor: FC<SingleAuthorProps> = ({ author }) => {
    return (
        <div className="nc-SingleAuthor flex">
            {author?.user_profiles && (
                <>
                    <div>
                        <Avatar
                            imgUrl={author?.user_profiles?.avatar?.original!}
                            userName={author?.users?.name}
                            sizeClass="h-12 w-12 text-lg sm:text-xl sm:h-24 sm:w-24"
                        />
                    </div>
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">YAZAR</span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <div>{author?.users?.name}</div>
                        </h2>
                        <span className="block mt-1 text-sm text-neutral-500 sm:text-base dark:text-neutral-300 line-clamp-2">
                            {author?.user_profiles?.bio}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default SingleAuthor;
