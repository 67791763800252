import CategoySlider from "components/CategoySlider";
import Heading from "components/Heading/Heading";
import { TaxonomyType } from "data/types";
import { useCategoriesQuery } from "framework/rest/category/categories.query";
import { FC, ReactNode } from "react";

export interface SectionSliderNewCategoriesProps {
    className?: string;
    heading: string;
    subHeading: string | ReactNode;
    categories: TaxonomyType[];
    categoryCardType?: "card1" | "card2" | "card3" | "card4" | "card5";
    itemPerRow?: 4 | 5;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
    heading,
    subHeading,
    className = "",
    categories,
    itemPerRow = 5,
    categoryCardType = "card3",
}) => {
    const { data, isLoading } = useCategoriesQuery({
        limit: 10,
    });

    const featuredCategory = data?.categories.data.filter((category) => category.featured === 1);

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className={`nc-SectionSliderNewCategories ${className}`}>
            <Heading desc={subHeading}>{heading}</Heading>
            <CategoySlider data={featuredCategory || []} itemPerRow={itemPerRow} />
        </div>
    );
};

export default SectionSliderNewCategories;
