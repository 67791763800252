import { useCreateSubscribe } from 'framework/rest/subscribe/subscribe.query';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { FaArrowsRotate } from 'react-icons/fa6';

export default function PreRequestInput() {
    const [email, setEmail] = useState('');
    const { isLoading, refetch } = useCreateSubscribe({
        email,
        isPreRequest: true,
    });
    const cuboidForm = useRef({} as HTMLFormElement);
    const emailRef = useRef({} as HTMLInputElement);
    const submitIconRef = useRef({} as HTMLLabelElement);
    const resetIconRef = useRef({} as HTMLSpanElement);

    const handleEmailFocus = () => {
        cuboidForm.current.classList.add('ready');
    };

    const handleEmailBlur = () => {
        if (cuboidForm.current.value === '') {
            cuboidForm.current.classList.remove('ready');
        }
    };

    const handleEmailKeyup = () => {
        submitIconRef.current.classList.toggle('active', emailRef.current.value.length > 0);
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        cuboidForm.current.classList.remove('ready');

        refetch();

        if (isLoading) {
            cuboidForm.current.classList.add('loading');
        } else {
            cuboidForm.current.classList.remove('loading');
            cuboidForm.current.classList.add('complete');
            setEmail('');
        }
    };

    const handleResetClick = () => {
        cuboidForm.current.classList.remove('complete');
    };

    useEffect(() => {
        emailRef.current.addEventListener('focus', handleEmailFocus);
        emailRef.current.addEventListener('blur', handleEmailBlur);
        emailRef.current.addEventListener('keyup', handleEmailKeyup);
        resetIconRef.current.addEventListener('click', handleResetClick);

        return () => {
            emailRef?.current?.removeEventListener('focus', handleEmailFocus);
            emailRef?.current?.removeEventListener('blur', handleEmailBlur);
            emailRef?.current?.removeEventListener('keyup', handleEmailKeyup);
            resetIconRef?.current?.removeEventListener('click', handleResetClick);
        };
    }, []);

    return (
        <div id="cuboid">
            <form
                ref={cuboidForm}
                onSubmit={handleFormSubmit}
            >
                <div>
                    <p className="cuboid-text">ÖNCELİKLİ LİSTEYE KAYIT OLUN</p>
                </div>
                <div>
                    <label
                        htmlFor="submit"
                        className="submit-icon"
                        ref={submitIconRef}
                    >
                        <FaChevronRight />
                    </label>
                    <input
                        type="text"
                        id="email"
                        className="cuboid-text"
                        placeholder="E-Posta Adresiniz"
                        ref={emailRef}
                        value={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                    />
                    <input
                        type="submit"
                        id="submit"
                    />
                </div>
                <div>
                    <p className="cuboid-text loader">Lütfen Bekleyiniz...</p>
                </div>
                <div>
                    <span
                        className="reset-icon"
                        ref={resetIconRef}
                    >
                        <FaArrowsRotate color="#FFFFFF" />
                    </span>
                    <p className="cuboid-text finish">Talebiniz alınmıştır. Teşekkür ederiz.</p>
                </div>
            </form>
        </div>
    );
}
