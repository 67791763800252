import ModalReportItem from 'components/ModalReportItem/ModalReportItem';
import NcDropDown, { NcDropDownItem } from 'components/NcDropDown/NcDropDown';
import { FC, useState } from 'react';
import { LuCopyCheck } from 'react-icons/lu';
import twFocusClass from 'utils/twFocusClass';
import ModalHideAuthor from './ModalHideAuthor';

export interface AccountActionDropdownProps {
    containerClassName?: string;
    iconClass?: string;
    dropdownPositon?: 'up' | 'down';
}

const AccountActionDropdown: FC<AccountActionDropdownProps> = ({
    containerClassName = 'h-8 w-8 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700',
    iconClass = 'h-6 w-6',
    dropdownPositon = 'down',
}) => {
    let actions: NcDropDownItem[] = [
        {
            id: 'copylink',
            name: 'Copy link',
            icon: <LuCopyCheck />,
        },

        //     {
        //         id: "hideThisAuthor",
        //         name: "Hide this author",
        //         icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        //   <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
        // </svg>`,
        //     },
        //     {
        //         id: "reportThisArticle",
        //         name: "Report this author",
        //         icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        //   <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
        // </svg>
        // `,
        //     },
    ];

    //
    const [isReporting, setIsReporting] = useState(false);
    const [showModalHideAuthor, setShowModalHideAuthor] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const openModalReportPost = () => setIsReporting(true);
    const closeModalReportPost = () => setIsReporting(false);

    const openModalHideAuthor = () => setShowModalHideAuthor(true);
    const onCloseModalHideAuthor = () => setShowModalHideAuthor(false);

    const hanldeClickDropDown = (item: (typeof actions)[number]) => {
        if (item.id === 'copylink') {
            navigator.clipboard.writeText(window.location.origin + '/author/this-is-slug');
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1000);
            return;
        }
        if (item.id === 'reportThisArticle') {
            return openModalReportPost();
        }
        if (item.id === 'hideThisAuthor') {
            return openModalHideAuthor();
        }

        return;
    };

    const renderMenu = () => {
        if (isCopied) {
            actions = actions.map(item => {
                if (item.id !== 'copylink') return item;
                return {
                    ...item,
                    name: 'Link Copied',
                };
            });
        }
        return (
            <NcDropDown
                className={`text-neutral-500 dark:text-neutral-400 flex items-center justify-center rounded-full ${containerClassName} ${twFocusClass()}`}
                triggerIconClass={iconClass}
                data={actions}
                panelMenusClass={dropdownPositon === 'up' ? 'origin-bottom-right bottom-0' : undefined}
                onClick={hanldeClickDropDown}
            />
        );
    };

    return (
        <div>
            {renderMenu()}

            <ModalReportItem
                show={isReporting}
                onCloseModalReportItem={closeModalReportPost}
            />
            <ModalHideAuthor
                show={showModalHideAuthor}
                onCloseModalHideAuthor={onCloseModalHideAuthor}
            />
        </div>
    );
};

export default AccountActionDropdown;
