import { ArrowRightIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import Textarea from "components/Textarea/Textarea";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { toast } from "react-toastify";
import ButtonSecondary from "../../../../components/Button/ButtonSecondary";
import Image from "../../../../components/Image";
import Nav from "../../../../components/Nav/Nav";
import NavItem from "../../../../components/NavItem/NavItem";
import FileInput from "../../../../components/file-input";
import { useSubmitPost } from "../../../../framework/rest/auth/auth.query";
import { SubmitPostType } from "../../../../framework/rest/auth/auth.service";
import { useCategoriesQuery } from "../../../../framework/rest/category/categories.query";
import { useTagsQuery } from "../../../../framework/rest/tags/tag.query";
import Layout from "../../layout";
import LayoutDashboard from "../layout";

interface LoginInputType {
    userId: number;
    post_title: string;
    post_excerpt: string;
    category: string;
    tags: string;
    videoUrl: string;
    post_content: string;
}

const TABS = ["video", "fotoğraf", "metin"];

const DashboardSubmitPost = () => {
    const { data: tagCategory, isLoading: categoryLoading } = useCategoriesQuery({});
    const { data: tagData, isLoading: tagLoading } = useTagsQuery({});
    const { data, isLoading } = useTagsQuery({});
    const { mutate: submitPost, isLoading: loading } = useSubmitPost();
    const [tabActive, setTabActive] = useState<string>(TABS[0]);
    const [messageContent, setMessageContent] = useState("");
    const [addYoutubePhoto, setAddYoutubePhoto] = useState(false);
    const [categories, setCategories] = useState<any>({});
    const [tags, setTags] = useState<any>([]);
    const [videoImage, setVideoImage] = useState<any>();

    const defaultValues = {
        userId: 0,
        post_title: "",
        post_excerpt: "",
        category: "",
        tags: "",
        video_url: "",
        post_content: "",
        image: null,
        gallery: null,
    };
    const { register, handleSubmit, control, reset } = useForm({
        defaultValues,
    });

    const onSubmit = async ({
        type,
        post_title,
        post_excerpt,
        post_content,
        video_url,
        image,
        gallery,
    }: SubmitPostType) => {
        post_content = messageContent;

        if (tabActive === "video" && !addYoutubePhoto) {
            const videoUrlArray = video_url!.split("=");
            setVideoImage({
                id: 0,
                url: null,
                original: `https://img.youtube.com/vi/${videoUrlArray[1]}/maxresdefault.jpg`,
                thumbnail: `https://img.youtube.com/vi/${videoUrlArray[1]}/maxresdefault.jpg`,
            });
        }

        submitPost(
            {
                type: tabActive === "fotoğraf" ? "photo" : tabActive === "video" ? "video" : "textual",
                post_title,
                post_excerpt,
                post_content,
                video_url: tabActive === "video" ? video_url : "",
                categories: { value: categories?.id, label: categories?.name },
                tags: tags.map((k: any) => {
                    return { value: k?.id, label: k?.name };
                }),
                image: tabActive === "video" && !addYoutubePhoto ? videoImage : image,
                gallery: tabActive === "fotoğraf" ? gallery : null,
            },
            {
                onSuccess: (data: any) => {
                    toast.success("Başarılı");
                    reset();
                    setMessageContent("");
                    setCategories({});
                    setTags([]);
                },
                onError: (error: any) => {
                    toast.error("Başarısız");
                },
            }
        );
    };

    const handleClickTab = (item: string) => {
        if (item === "textual") setAddYoutubePhoto(false);

        if (item === tabActive) return;

        setTabActive(item);
    };

    const handleAddYoutubePhoto = () => {
        setAddYoutubePhoto(!addYoutubePhoto);
    };

    const handleVideoPhoto = (e: ChangeEvent<HTMLInputElement>) => {
        const videoUrlArray = e.target.value!.split("=");
        setVideoImage({
            id: 0,
            url: null,
            original: `https://img.youtube.com/vi/${videoUrlArray[1]}/maxresdefault.jpg`,
            thumbnail: `https://img.youtube.com/vi/${videoUrlArray[1]}/maxresdefault.jpg`,
        });
    };
    return (
        <Layout>
            <LayoutDashboard>
                <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
                    <form
                        className="grid md:grid-cols-2 gap-6"
                        action="#"
                        method="post"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
                            <Nav className="sm:space-x-2">
                                {TABS.map((item, index) => (
                                    <NavItem
                                        key={index}
                                        isActive={tabActive === item}
                                        onClick={() => handleClickTab(item)}
                                    >
                                        {item}
                                    </NavItem>
                                ))}
                            </Nav>
                            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
                            <div className="flex justify-end"></div>
                        </div>
                        <label className="block md:col-span-2">
                            <Label>Yazı başlığı *</Label>

                            <Input
                                type="text"
                                placeholder="Yazı başlığı"
                                className="mt-1"
                                {...register("post_title", { required: true })}
                            />
                        </label>
                        <label className="block md:col-span-2">
                            <Label>Alıntı Gönder</Label>

                            <Textarea
                                className="mt-1"
                                rows={4}
                                placeholder="Alıntı Gönder"
                                {...register("post_excerpt", { required: true })}
                            />
                            <p className="mt-1 text-sm text-neutral-500">
                                Makaleniz için kısa açıklama. URL'ler hiper bağlantılıdır.
                            </p>
                        </label>

                        <label className="block">
                            <Label>Kategori</Label>

                            <Select
                                options={tagCategory?.categories.data}
                                name="categories"
                                isLoading={categoryLoading}
                                onChange={(e: any) => setCategories(e)}
                                getOptionLabel={(option: any) => option.name}
                                getOptionValue={(option: any) => option.id}
                                className="mt-1"
                                styles={{
                                    control: (baseStyles: any, state: any) => ({
                                        ...baseStyles,
                                        // borderColor: state.isFocused ? 'grey' : 'red',
                                        borderRadius: "9999px",
                                        height: "44px",
                                    }),
                                }}
                                classNamePrefix="select"
                            />
                        </label>
                        <label className="block">
                            <Label>Etiketler</Label>

                            <Select
                                options={tagData?.tags.data}
                                isMulti
                                className="mt-1 select-tags"
                                isLoading={tagLoading}
                                onChange={(e: any) => setTags(e)}
                                getOptionLabel={(option: any) => option.name}
                                getOptionValue={(option: any) => option.id}
                                styles={{
                                    control: (baseStyles: any, state: any) => ({
                                        ...baseStyles,
                                        // borderColor: state.isFocused ? 'grey' : 'red',
                                        borderRadius: "9999px",
                                        minHeight: "44px",
                                    }),
                                }}
                                classNamePrefix="select"
                            />
                        </label>

                        {tabActive === "video" && (
                            <label className="block md:col-span-2">
                                <Label>Video bağlantısı</Label>

                                <Input
                                    type="text"
                                    placeholder="Video bağlantısı"
                                    className="mt-1"
                                    {...register("video_url", { required: false })}
                                    onChange={handleVideoPhoto}
                                />
                                <div className="mt-4">
                                    <div className="w-full h-full grid grid-rows-1 grid-cols-3">
                                        <div className="col-span-1">
                                            {!addYoutubePhoto && videoImage && (
                                                <Image
                                                    src={videoImage?.original || ""}
                                                    className="object-cover"
                                                    width={130}
                                                    height={130}
                                                    alt="single"
                                                />
                                            )}
                                        </div>
                                        <div className="col-span-2 text-end">
                                            <ButtonSecondary
                                                type="button"
                                                fontSize="text-sm font-medium"
                                                className="md:col-span-2"
                                                onClick={handleAddYoutubePhoto}
                                            >
                                                <span> Kapak Fotoğrafını Belirle</span>
                                                <ArrowRightIcon className="w-6 h-6 ml-3" />
                                            </ButtonSecondary>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        )}

                        {(tabActive === "fotoğraf" || tabActive === "metin" || addYoutubePhoto) && (
                            <div className="block md:col-span-2">
                                <Label>Kapak Fotoğrafı</Label>

                                <FileInput name="image" control={control} multiple={false} />
                            </div>
                        )}
                        {tabActive === "fotoğraf" && (
                            <div className="block md:col-span-2">
                                <Label>Özellikli resim</Label>

                                <FileInput name="gallery" control={control} multiple={true} />
                            </div>
                        )}
                        <label className="block md:col-span-2">
                            <Label> Mesaj İçeriği</Label>
                            <ReactQuill
                                theme="snow"
                                value={messageContent}
                                onChange={(e) => setMessageContent(e)}
                                style={{ height: "200px" }}
                                className="mb-10"
                            />
                        </label>

                        <ButtonPrimary className="md:col-span-2" type="submit" disabled={loading}>
                            {loading ? "Loading..." : "Gönderi Gönder"}
                        </ButtonPrimary>
                    </form>
                </div>
            </LayoutDashboard>
        </Layout>
    );
};

export default DashboardSubmitPost;
