import { useMenuQuery } from "framework/rest/menu/use-menu.query";
import { FC } from "react";
import NavigationItem from "./NavigationItem";

interface Props {
    className?: string;
}

const Navigation: FC<Props> = ({ className = "flex" }) => {
    const { data, isLoading: loading } = useMenuQuery();
    if (loading) return <div></div>;

    return (
        <ul className={`nc-Navigation items-center ${className}`}>
            {data?.data.map((item: any) => (
                <NavigationItem
                    key={item.id}
                    menuItem={{
                        ...item,
                        type: item?.sub_menu?.length ? "dropdown" : "none",
                        children: item.sub_menu,
                    }}
                />
            ))}
        </ul>
    );
};

export default Navigation;
