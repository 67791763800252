import { Route } from 'routers/types';
import bg4 from '../images/caroline.png';
import bg1 from '../images/ipanema.png';
import bg5 from '../images/karalar-prefabrik.jpg';
import { default as a6, default as bg6 } from '../images/kips.png';
import bg2 from '../images/quality-hotel.png';
import bg3 from '../images/sfit-house.png';
import a2 from './avatars/1.png';
import a4 from './avatars/4.png';
import a5 from './avatars/5.jpg';
import __authors from './jsons/__users.json';
import { PostAuthorType } from './types';

let as = [a6, a2, a6, a4, a5, a6];
let bs = [bg1, bg2, bg3, bg4, bg5, bg6];

const DEMO_AUTHORS: PostAuthorType[] = __authors.map((item, index) => ({
    ...item,
    avatar: !!item?.avatar ? item.avatar : as[index],
    bgImage: bs[index] || item.bgImage,
    href: item.href as Route,
}));

export { DEMO_AUTHORS };
