import SingleMetaAction2 from 'app/(singles)/SingleMetaAction2';
import SingleTitle from 'app/(singles)/SingleTitle';
import CategoryBadgeList from 'components/CategoryBadgeList/CategoryBadgeList';
import Image from 'components/Image';
import NcPlayIcon from 'components/NcPlayIcon/NcPlayIcon';
import PostMeta2 from 'components/PostMeta2/PostMeta2';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import isSafariBrowser from 'utils/isSafariBrowser';
import POSTS_DATA from '../../../../data/jsons/__postsVideo.json';
import Layout from '../layout';

const PageSingleVideo = () => {
    const location = useLocation();
    const [isPlay, setIsPlay] = useState(false);
    const [isRendered, setIsRendered] = useState(false);
    const searchId = location.search.replace('?id=', '');
    const post: any = POSTS_DATA.find(x => x.id === searchId);

    useEffect(() => {
        setIsRendered(true);
    }, []);

    const renderMainVideo = () => {
        return (
            <div className="">
                {isSafariBrowser() && !isPlay && (
                    <div
                        className="absolute inset-0 z-10 cursor-pointer "
                        onClick={() => setIsPlay(true)}
                    >
                        <Image
                            src={post?.featuredImage}
                            className="object-cover  w-full h-full"
                            sizes="(max-width: 1024px) 100vw, 50vw"
                            alt="single"
                        />
                        <div className="absolute inset-0 flex items-center justify-center">
                            <NcPlayIcon />
                        </div>
                    </div>
                )}
                {isRendered && (
                    <ReactPlayer
                        url={post?.videoUrl}
                        className="absolute inset-0"
                        playing={isSafariBrowser() ? isPlay : true}
                        width="100%"
                        height="100%"
                        controls
                        light={isSafariBrowser() ? false : post?.featuredImage}
                        playIcon={<NcPlayIcon />}
                    />
                )}
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className={`nc-SingleHeader `}>
                <div className="space-y-5 dark text-neutral-100">
                    <CategoryBadgeList
                        itemClass="!px-3"
                        categories={[]}
                    />
                    <SingleTitle
                        mainClass="text-neutral-900 font-semibold text-3xl md:!leading-[120%] dark:text-neutral-100"
                        title={post?.title!}
                    />

                    <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
                    <div className="flex flex-col space-y-5">
                        <PostMeta2
                            meta={post!}
                            size="large"
                            className="leading-none flex-shrink-0"
                            hiddenCategories
                            avatarRounded="rounded-full shadow-inner"
                        />
                        <SingleMetaAction2 post={post} />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Layout>
            <header className="container relative py-14 lg:py-20 flex flex-col lg:flex-row lg:items-center">
                <div className="nc-PageSingleVideo__headerWrap absolute inset-y-0 transform translate-x-1/2 right-1/2 w-screen lg:translate-x-0 lg:w-[calc(100vw/2)] bg-neutral-900 dark:bg-black dark:bg-opacity-50 lg:rounded-r-[40px]"></div>
                <div className="pb-10 lg:pb-0 lg:pr-10 relative">{renderHeader()}</div>
                <div className="relative lg:w-8/12 flex-shrink-0">
                    <div className="aspect-w-16 aspect-h-16 sm:aspect-h-9 border-4 border-neutral-300 dark:border-neutral-800 shadow-2xl bg-neutral-800 rounded-3xl overflow-hidden z-0">
                        {renderMainVideo()}
                    </div>
                </div>
            </header>
        </Layout>
    );
};

export default PageSingleVideo;
