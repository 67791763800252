import CardLarge1 from 'components/CardLarge1/CardLarge1';
import Heading from 'components/Heading/Heading';
import { PostDataType } from 'data/types';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

export interface SectionLargeSliderProps {
    className?: string;
    heading?: string;
    subTitle?: string | ReactNode;
    posts: PostDataType[];
}

const SectionLargeSlider: FC<SectionLargeSliderProps> = ({ posts, heading, className = '', subTitle }) => {
    const [indexActive, setIndexActive] = useState(0);

    const handleClickNext = useCallback(() => {
        setIndexActive(state => {
            if (state >= posts.length - 1) {
                return 0;
            }
            return state + 1;
        });
    }, [posts?.length]);

    const handleClickPrev = useCallback(() => {
        setIndexActive(state => {
            if (state === 0) {
                return posts.length - 1;
            }
            return state - 1;
        });
    }, [posts?.length]);

    useEffect(() => {
        if (posts?.length > 0) {
            const timer = setInterval(() => {
                handleClickNext();
            }, 5000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [handleClickNext, posts]);

    return (
        <div className={`nc-SectionLargeSlider relative ${className}`}>
            {!!heading && <Heading desc={subTitle}>{heading}</Heading>}
            {posts?.map((item, index) => {
                if (indexActive !== index) return null;
                return (
                    <CardLarge1
                        key={index}
                        onClickNext={handleClickNext}
                        onClickPrev={handleClickPrev}
                        post={item}
                    />
                );
            })}
        </div>
    );
};

export default SectionLargeSlider;
