import CategoryBadgeList from 'components/CategoryBadgeList/CategoryBadgeList';
import Link from 'components/Link';
import PostCardMetaV2 from 'components/PostCardMeta/PostCardMetaV2';
import PostCardSaveAction from 'components/PostCardSaveAction/PostCardSaveAction';
import PostFeaturedMedia from 'components/PostFeaturedMedia/PostFeaturedMedia';
import { PostDataType } from 'data/types';
import { FC, useState } from 'react';

export interface Card10Props {
    className?: string;
    post: PostDataType;
}

const Card10: FC<Card10Props> = ({ className = 'h-full', post }) => {
    const { href, categories } = post;
    const [isHover, setIsHover] = useState(false);

    return (
        <div
            className={`nc-Card10 relative flex flex-col ${className}`}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <Link
                href={href}
                className="absolute inset-0"
            />
            <div className="block group rounded-3xl flex-shrink-0 relative w-full aspect-w-9 aspect-h-7 sm:aspect-h-9 overflow-hidden z-0">
                <div>
                    <PostFeaturedMedia
                        post={post}
                        isHover={isHover}
                    />
                </div>

                <Link
                    href={href}
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity"
                ></Link>
            </div>
            <div className="absolute top-3 inset-x-3 flex justify-between items-start space-x-4 z-10">
                <CategoryBadgeList categories={categories} />
                <PostCardSaveAction hidenReadingTime />
            </div>

            <div className="space-y-2.5 mt-4">
                <PostCardMetaV2 meta={post} />
            </div>
        </div>
    );
};

export default Card10;
