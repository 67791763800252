import Avatar from 'components/Avatar/Avatar';
import Link from 'components/Link';
import { PostDataType } from 'data/types';
import moment from 'moment';
import { FC } from 'react';

export interface PostCardMetaProps {
    className?: string;
    meta: Pick<PostDataType, 'date' | 'author' | 'published_at' | 'user_profiles' | 'users'>;
    hiddenAvatar?: boolean;
    avatarSize?: string;
}

const PostCardMeta: FC<PostCardMetaProps> = ({
    className = 'leading-none text-xs',
    meta,
    hiddenAvatar = false,
    avatarSize = 'h-7 w-7 text-sm',
}) => {
    const { date, author, published_at, user_profiles, users } = meta;

    return (
        <div
            className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
        >
            <Link
                href={author?.href}
                className="relative flex items-center space-x-2"
            >
                {!hiddenAvatar && (
                    <Avatar
                        radius="rounded-full"
                        sizeClass={avatarSize}
                        imgUrl={user_profiles?.avatar?.original ?? author?.avatar}
                        userName={author?.displayName ?? users?.name}
                    />
                )}
                <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
                    {author?.displayName ?? users?.name}
                </span>
            </Link>
            <>
                <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">·</span>
                <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                    {date ?? moment(published_at).format('DD.MM.YYYY')}
                </span>
            </>
        </div>
    );
};

export default PostCardMeta;
