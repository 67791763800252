import { FC } from 'react';
import Button, { ButtonProps } from './Button';

export interface Props extends ButtonProps {}

const ButtonPrimary: FC<Props> = props => {
    return (
        <Button
            {...props}
            className={`bg-gradient-to-t from-[#564c98] to-[#111023] hover:from-[#6f62c4] hover:to-[#24224b] disabled:from-[#e5e7eb] disabled:to-[#e5e7eb] disabled:text-gray-500 ${props.className}`}
        />
    );
};

export default ButtonPrimary;
