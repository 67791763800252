import { FC, useState } from 'react';
import { PiHandsClappingLight } from 'react-icons/pi';
import convertNumbThousand from 'utils/convertNumbThousand';

export interface PostCardLikeActionProps {
    className?: string;
    likeCount?: number;
    liked?: boolean;
}

const PostCardLikeAction: FC<PostCardLikeActionProps> = ({
    className = 'px-3 h-8 text-xs',
    likeCount = 34,
    liked = false,
}) => {
    const [isLiked, setisLiked] = useState(liked);
    const [count, setCount] = useState(likeCount);

    return (
        <button
            className={`nc-PostCardLikeAction relative min-w-[68px] flex items-center rounded-full leading-none group transition-colors ${className} ${
                isLiked
                    ? 'text-rose-600 bg-rose-50 dark:bg-rose-100'
                    : 'text-neutral-700 bg-neutral-50 dark:text-neutral-200 dark:bg-neutral-800 hover:bg-rose-50 dark:hover:bg-rose-100 hover:text-rose-600 dark:hover:text-rose-500'
            }`}
            onClick={() => {
                setisLiked(!isLiked);
                isLiked ? setCount(prev => count - 1) : setCount(prev => count + 1);
            }}
            title="Liked"
        >
            <PiHandsClappingLight size={20} />

            {count && (
                <span className={`ml-1 ${isLiked ? 'text-rose-600' : 'text-neutral-900 dark:text-neutral-200'}`}>
                    {convertNumbThousand(count)}
                </span>
            )}
        </button>
    );
};

export default PostCardLikeAction;
