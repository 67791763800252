import { ArrowRightIcon } from '@heroicons/react/24/outline';
import ButtonSecondary from 'components/Button/ButtonSecondary';
import NcImage from 'components/NcImage/NcImage';
import { Category } from 'framework/rest/types';
import { FC, MouseEventHandler } from 'react';
import { RiArrowRightUpLine } from 'react-icons/ri';

export interface CardCategory7Props {
    className?: string;
    category: Category;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

const CardCategory7: FC<CardCategory7Props> = ({ className = '', category, onClick = () => {} }) => {
    const { image, banner_image, slug, name, details, products_count } = category;
    const url = `${process.env.REACT_APP_START_URL}?category=${slug}`;
    const count = name.includes('_') ? name.split('_')[1].trim() : products_count;
    const newName = name.includes('_') ? name.split('_')[0].trim() : name;

    return (
        <div
            className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-neutral-800 rounded-3xl ${className}`}
            onClick={onClick}
        >
            <span className="relative flex-shrink-0 ">
                <div>
                    {image?.length! > 0 && (
                        <NcImage
                            alt="author"
                            containerClassName="flex aspect-w-7 aspect-h-5 w-full h-0"
                            src={image![0].original}
                            fill
                            sizes="(max-width: 600px) 480px, 33vw"
                        />
                    )}
                </div>
                <div className="absolute top-3 inset-x-3 flex">
                    <div className=" py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium">
                        {count}
                        {!name.includes('_') && <ArrowRightIcon className="w-5 h-5 text-yellow-600 ml-3" />}
                    </div>
                </div>
            </span>

            <div className="-mt-8 mx-4 my-8 text-center">
                <ButtonSecondary
                    className="!bg-[#88E64A] !text-black !px-2 sm:!px-4 !py-2 mt-4 !hover:bg-[#9ef763] !text-[2.2vw] sm:!text-[1.7vw] md:!text-[1.4vw] lg:!text-sm !font-semibold"
                    type="button"
                >
                    Toplantı Oluştur
                    <RiArrowRightUpLine size={20} />
                </ButtonSecondary>
                <span className="mt-3 block">
                    <h2 className={`text-base font-medium`}>
                        <span className="line-clamp-1">{newName}</span>
                    </h2>
                    <span className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}>{details}</span>
                </span>
            </div>
        </div>
    );
};

export default CardCategory7;
