import ButtonPrimary from 'components/Button/ButtonPrimary';
import { Form } from 'components/form';
import Image from 'components/Image';
import Input from 'components/Input/Input';
import { useStoreCreateMutation } from 'framework/rest/store-request/create-store-request.mutation';
import { Category } from 'framework/rest/types';
import { SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ModalBgPattern } from './modal-bg-pattern';
import Checkbox from 'components/Checkbox/Checkbox';
import Link from 'components/Link';
import { useState } from 'react';

type RequestModel = {
    name: string;
    surname: string;
    email: string;
    phone: string;
};

const requestModelValidationSchema = yup.object().shape({
    name: yup.string().max(20).required(),
    surname: yup.string().max(20).required(),
    email: yup.string().email().required(),
    phone: yup.string().max(10).required(),
});

type StoreRequestModalProps = {
    item: Category;
    setIsOpen: (isOpen: boolean) => void;
};

export default function StoreRequestModal({ item, setIsOpen = () => {} }: StoreRequestModalProps) {
    const newName = item?.name.includes('_') ? item?.name.split('_')[1].trim() : item?.name;
    const sectorName = item?.name.includes('_') ? item?.name.split('_')[0].trim() : item?.name;
    const { mutate } = useStoreCreateMutation();
    const [isChecked, setIsChecked] = useState(false);

    const onSubmit: SubmitHandler<RequestModel> = data => {
        const requestData = {
            fullname: `${data.name} ${data.surname}`,
            email: data.email,
            phone: data.phone,
            category_data: {
                name: item.name,
            },
        };

        mutate(requestData, {
            onSuccess: () => {
                toast.success('Talebiniz iletildi');
                setIsOpen(false);
            },
            onError: e => {
                toast.success('Talebiniz alınamadı, lütfen tekrar deneyiniz');
            },
        });
    };

    return (
        <div className="bg-white px-6 pt-10 pb-8 sm:px-8 lg:p-12 relative">
            <ModalBgPattern className="absolute bottom-0 left-0 text-light dark:text-dark-300 dark:opacity-60" />
            <div className="relative z-10 flex items-center">
                <div className="w-full shrink-0 text-left md:w-[380px]">
                    <div className="flex flex-col pb-5 text-center lg:pb-9 xl:pb-10 xl:pt-2">
                        <div className="flex justify-center mb-3">
                            <Image
                                src={item?.image && item?.image[0]?.original}
                                className="rounded-full shadow-md"
                                width={100}
                                height={100}
                            />
                        </div>
                        <h2 className="text-lg font-medium tracking-[-0.3px] text-dark dark:text-light lg:text-xl flex items-center justify-center gap-4">
                            <span>{newName}</span>
                            <span>|</span>
                            <span className="font-semibold text-base text-green-500">{sectorName}</span>
                        </h2>
                        <div className="mt-1.5 text-sm leading-6 tracking-[0.2px] text-gray-600 lg:mt-2.5 xl:mt-3">
                            {item?.details}
                        </div>
                    </div>

                    <Form<RequestModel>
                        onSubmit={onSubmit}
                        validationSchema={requestModelValidationSchema}
                        className="space-y-4 lg:space-y-5"
                    >
                        {({ register, formState: { errors } }) => (
                            <>
                                <div className="grid sm:grid-cols-2 gap-4">
                                    <Input
                                        placeholder="Ad"
                                        className="bg-white dark:bg-dark-300 rounded-md"
                                        {...register('name')}
                                        autoComplete="off"
                                    />
                                    <Input
                                        placeholder="Soyad"
                                        className="bg-white dark:bg-dark-300 rounded-md"
                                        {...register('surname')}
                                        autoComplete="off"
                                    />
                                </div>
                                <Input
                                    placeholder="E-Posta"
                                    className="bg-white dark:bg-dark-300 rounded-md"
                                    {...register('email')}
                                    autoComplete="off"
                                />
                                <Input
                                    placeholder="Telefon"
                                    className="bg-white dark:bg-dark-300 rounded-md"
                                    {...register('phone')}
                                    autoComplete="off"
                                />

                                <Checkbox
                                    label={
                                        <span>
                                            <Link
                                                href={'#'}
                                                className="underline"
                                            >
                                                Kullanım Sözleşmesi
                                            </Link>
                                            'ni okudum onaylıyorum.
                                        </span>
                                    }
                                    name="contract"
                                    onChange={e => setIsChecked(e.currentTarget.checked)}
                                />

                                <ButtonPrimary
                                    disabled={!isChecked}
                                    type="submit"
                                    className="!mt-5 w-full text-sm tracking-[0.2px] lg:!mt-7 rounded-md"
                                >
                                    Gönder
                                </ButtonPrimary>
                            </>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
}
