import Image from "components/Image";
import Link from "components/Link";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import { PostDataType } from "data/types";
import { FC } from "react";
import GallerySlider from "./GallerySlider";
import MediaAudio from "./MediaAudio";
import MediaVideo from "./MediaVideo";

export interface PostFeaturedMediaProps {
    className?: string;
    post: PostDataType;
    isHover?: boolean;
}

const PostFeaturedMedia: FC<PostFeaturedMediaProps> = ({ className = "w-full h-full", post, isHover = false }) => {
    const { featuredImage, image, postType, videoUrl, video_url, galleryImgs, audioUrl, href, type, id } = post;

    const isPostMedia = () => postType === "video" || postType === "audio" || type === "video" || type === "audio";
    let url = "#";

    if (type === "textual") {
        url = `/post-textual/${id}`;
    } else if (type === "photo") {
        url = `/post-photo/${id}`;
    } else if (type === "video") {
        url = `/post-video/${id}`;
    }

    const renderGallerySlider = () => {
        if (!galleryImgs) return null;
        return (
            <GallerySlider
                href={href ?? url}
                galleryImgs={galleryImgs}
                className="absolute inset-0 z-10"
                galleryClass="absolute inset-0"
                ratioClass="absolute inset-0"
            />
        );
    };

    const renderContent = () => {
        // GALLERY
        if (postType === "gallery" || type === "gallery") {
            return renderGallerySlider();
        }

        // VIDEO
        if ((postType === "video" || type === "video") && (!!videoUrl || !!video_url) && isHover) {
            return <MediaVideo isHover videoUrl={videoUrl ?? video_url!} />;
        }

        // AUDIO
        if ((postType === "audio" || type === "audio") && !!audioUrl) {
            return <MediaAudio post={post} />;
        }

        // ICON
        return isPostMedia() ? (
            <span className="absolute inset-0 flex items-center justify-center ">
                <PostTypeFeaturedIcon
                    className="hover:scale-105 transform cursor-pointer transition-transform"
                    postType={postType || type}
                />
            </span>
        ) : null;
    };

    return (
        <div className={`nc-PostFeaturedMedia relative ${className}`}>
            {postType !== "gallery" && (
                <Image
                    alt="featured"
                    fill
                    className="object-cover"
                    src={image?.original ?? featuredImage}
                    sizes="(max-width: 600px) 480px, 800px"
                />
            )}
            {renderContent()}
            {postType !== "gallery" && (
                <Link
                    href={href ?? url}
                    className={`block absolute inset-0 ${
                        !postType || postType === "standard"
                            ? "bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
                            : ""
                    }`}
                />
            )}
        </div>
    );
};

export default PostFeaturedMedia;
