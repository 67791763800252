import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostMeta2 from "components/PostMeta2/PostMeta2";
import { FC } from "react";
import SingleMetaAction2 from "./SingleMetaAction2";
import SingleTitle from "./SingleTitle";

export interface SingleHeaderProps {
    hiddenDesc?: boolean;
    titleMainClass?: string;
    className?: string;
}

const SingleHeader: FC<SingleHeaderProps> = ({ titleMainClass, hiddenDesc = false, className = "" }) => {
    return (
        <>
            <div className={`nc-SingleHeader ${className}`}>
                <div className="space-y-5">
                    <CategoryBadgeList itemClass="!px-3" categories={[]} />
                    <SingleTitle mainClass={titleMainClass} title={"Trending web & landing page designs in 2023"} />
                    {!hiddenDesc && (
                        <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perspiciatis tempora obcaecati
                            error ipsum voluptatibus sed adipisci ut maiores nesciunt quam.
                        </span>
                    )}
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
                        <PostMeta2
                            size="large"
                            className="leading-none flex-shrink-0"
                            hiddenCategories
                            avatarRounded="rounded-full shadow-inner"
                        />
                        <SingleMetaAction2 />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SingleHeader;
