import { SOCIALS_DATA, SocialType } from 'components/SocialsShare/SocialsShare';
import { FC } from 'react';
import { AiOutlineYoutube } from 'react-icons/ai';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn, FaXTwitter } from 'react-icons/fa6';

export interface SocialsListProps {
    className?: string;
    itemClass?: string;
    socials?: SocialType[];
}

const socialsDemo: SocialType[] = SOCIALS_DATA;

export const SOCIALS_2 = socialsDemo;

const SocialsList: FC<SocialsListProps> = ({ className = '', itemClass = 'block', socials = socialsDemo }) => {
    const svgIcons = {
        FacebookIcon: <FaFacebookF size={20} />,
        TwitterIcon: <FaXTwitter size={20} />,
        InstagramIcon: <FaInstagram size={20} />,
        YouTubeIcon: <AiOutlineYoutube size={20} />,
        LinkedinIcon: <FaLinkedinIn size={20} />,
    };
    // get from intenet youtube icon svg

    const checkIconInclude = (icon: string) => {
        const iconSvg = svgIcons[icon as keyof typeof svgIcons];
        if (iconSvg) {
            return iconSvg;
        }
        return icon;
    };
    const singleItem =
        socials.map((item: any) => {
            const icon = checkIconInclude(item.icon);
            return { ...item, icon };
        }) || '';

    return (
        <nav className={`nc-SocialsList flex space-x-3 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}>
            {singleItem
                ?.filter(x => x.url !== '#')
                .map((item, i) => (
                    <a
                        key={i}
                        className={`${itemClass}`}
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={item.id}
                    >
                        <div>{item.icon}</div>
                    </a>
                ))}
        </nav>
    );
};

export default SocialsList;
