import Avatar from 'components/Avatar/Avatar';
import Link from 'components/Link';
import { PostDataType } from 'data/types';
import moment from 'moment';
import { FC } from 'react';

export interface PostCardMetaProps {
    className?: string;
    meta: Pick<PostDataType, 'date' | 'author' | any>;
    hiddenAvatar?: boolean;
    avatarSize?: string;
}

const PostCard5EditMeta: FC<PostCardMetaProps> = ({
    className = 'leading-none text-xs',
    meta,
    hiddenAvatar = false,
    avatarSize = 'h-7 w-7 text-sm',
}) => {
    const { date, author } = meta;

    return (
        <div
            className={`nc-PostCardMeta inline-flex items-center text-neutral-800 dark:text-neutral-200 overflow-hidden text-xs w-full ${className}`}
        >
            <Link
                href={author?.href}
                className="relative flex items-center space-x-2"
            >
                {!hiddenAvatar && (
                    <Avatar
                        radius="rounded-full"
                        sizeClass={avatarSize}
                        imgUrl={meta?.user_profiles?.avatar?.thumbnail ?? ''}
                        userName={meta?.users?.name ?? ''}
                    />
                )}
                <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
                    {meta?.users?.name ?? ''}
                </span>
            </Link>
            <>
                <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">·</span>
                <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                    {moment(meta?.published_at ?? '').format('DD.MM.YYYY')}
                </span>
            </>
        </div>
    );
};

export default PostCard5EditMeta;
