import { CoreApi } from "utils/core-api";
import { API_ENDPOINTS } from "utils/endpoints";

export type LoginInputType = {
  email: string;
  password: string;
};
export type RegisterUserInputType = {
  name: string;
  email: string;
  password: string;
};

export type ChangePasswordInputType = {
  oldPassword: string;
  newPassword: string;
};
export type ForgetPasswordInputType = {
  email: string;
};
export type ResetPasswordInputType = {
  email: string;
  token: string;
  password: string;
};
export type VerifyPasswordInputType = {
  email: string;
  token: string;
};
export type SocialLoginInputType = {
  provider: string;
  access_token: string;
};
export type SendOtpCodeInputType = {
  phone_number: string;
};
export type VerifyOtpInputType = {
  phone_number: string;
  code: string;
  otp_id: string;
};
export type OtpLoginInputType = {
  phone_number: string;
  code: string;
  otp_id: string;
  name?: string;
  email?: string;
};
export type UpdateContactInput = {
  phone_number: string;
  code: string;
  otp_id: string;
  user_id: string;
};

export declare type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export declare type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /**
   * Loose type that allows any value. Be careful when passing in large `Int` or `Float` literals,
   * as they may not be parsed correctly on the server side. Use `String` literals if you are
   * dealing with really large numbers to be on the safe side.
   */
  Mixed: any;
  Upload: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime and timezone string in ISO 8601 format `Y-m-dTH:i:sO`, e.g. `2020-04-20T13:53:12+02:00`. */
  DateTimeTz: any;
};

export declare type AttachmentInsideObject = {
  height: Scalars["Int"];
  wdith: Scalars["Int"];
  url: Scalars["String"];
};

export declare type AttachmentBanner = {
  desktop?: Maybe<AttachmentInsideObject>;
  mobile?: Maybe<AttachmentInsideObject>;
};

export type SubmitPostType = {
  type?: string;
  post_title: string;
  post_excerpt: string;
  post_content?: string;
  video_url?: string;
  categories?: any;
  tags?: any;
  image?: Maybe<AttachmentBanner>;
  gallery?: Maybe<AttachmentBanner>;
};

class Auth extends CoreApi {
  login(input: LoginInputType) {
    return this.http.post(API_ENDPOINTS.LOGIN, input).then((res) => res.data);
  }
  socialLogin(input: SocialLoginInputType) {
    return this.http
      .post(API_ENDPOINTS.SOCIAL_LOGIN, input)
      .then((res) => res.data);
  }
  sendOtpCode(input: SendOtpCodeInputType) {
    return this.http
      .post(API_ENDPOINTS.SEND_OTP_CODE, input)
      .then((res) => res.data);
  }
  verifyOtpCode(input: VerifyOtpInputType) {
    return this.http
      .post(API_ENDPOINTS.VERIFY_OTP_CODE, input)
      .then((res) => res.data);
  }
  otpLogin(input: OtpLoginInputType) {
    return this.http
      .post(API_ENDPOINTS.OTP_LOGIN, input)
      .then((res) => res.data);
  }
  updateContact(input: UpdateContactInput) {
    return this.http
      .post(API_ENDPOINTS.UPDATE_CONTACT, input)
      .then((res) => res.data);
  }
  register(input: RegisterUserInputType) {
    return this.http
      .post(API_ENDPOINTS.REGISTER, input)
      .then((res) => res.data);
  }
  logout() {
    return this.http.post(API_ENDPOINTS.LOGOUT);
  }
  changePassword(input: ChangePasswordInputType) {
    return this.http
      .post(API_ENDPOINTS.CHANGE_PASSWORD, input)
      .then((res) => res.data);
  }
  forgetPassword(input: ForgetPasswordInputType) {
    return this.http
      .post(API_ENDPOINTS.FORGET_PASSWORD, input)
      .then((res) => res.data);
  }
  resetPassword(input: ResetPasswordInputType) {
    return this.http
      .post(API_ENDPOINTS.RESET_PASSWORD, input)
      .then((res) => res.data);
  }
  verifyForgetPassword(input: VerifyPasswordInputType) {
    return this.http
      .post(API_ENDPOINTS.VERIFY_FORGET_PASSWORD, input)
      .then((res) => res.data);
  }
  submitPost(input: SubmitPostType) {
    return this.http.post(API_ENDPOINTS.SUBMIT_POST, input).then((res) => res.data);
  }
}

export const AuthService = new Auth('auth');
