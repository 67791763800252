export const CART_KEY = 'chawk-cart';
export const TOKEN = 'token';
export const AUTH_TOKEN = 'auth_token';
export const AUTH_PERMISSIONS = 'auth_permissions';
export const LIMIT = 10;
export const SUPER_ADMIN = 'super_admin';
export const CUSTOMER = 'customer';
export const CHECKOUT = 'chawkbazar-checkout';
export const BILLING_ADDRESS = "billing_address";
export const SHIPPING_ADDRESS = "shipping_address";
