import SingleMetaAction2 from 'app/(singles)/SingleMetaAction2';
import SingleTitle from 'app/(singles)/SingleTitle';
import CategoryBadgeList from 'components/CategoryBadgeList/CategoryBadgeList';
import Image from 'components/Image';
import NcPlayIcon from 'components/NcPlayIcon/NcPlayIcon';
import PostMetaUser from 'components/PostMetaUser/Index';
import { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isSafariBrowser from 'utils/isSafariBrowser';
import NcImage from '../../../../components/NcImage/NcImage';
import ListingImageGallery from '../../../../components/listing-image-gallery/ListingImageGallery';
import { fetchPost } from '../../../../framework/rest/post/posts.query';
import usePathname from '../../../../hooks/usePathname';
import SingleContent from '../../SingleContent';
import Layout from '../layout';

const PageSinglePostVideo = () => {
    const [isPlay, setIsPlay] = useState(false);
    const [singlePost, setSinglePost] = useState<any>(null);
    const [youtubeHash, setYoutubeHash] = useState('');
    const router = useNavigate();
    const [searchParams] = useSearchParams();
    const modal = searchParams?.get('modal');

    const url = usePathname();
    const thisPathname = usePathname();
    const lastPart = url.split('/').pop() || '';

    useEffect(() => {
        fetchPost(lastPart).then(res => {
            setSinglePost(res);
        });
    }, [lastPart]);

    useEffect(() => {
        if (singlePost?.video_url) {
            const parsdUrl = singlePost?.video_url.split('/');
            setYoutubeHash(parsdUrl[parsdUrl.length - 1]);
        }
    }, [singlePost]);

    const handleCloseModalImageGallery = () => {
        let params = new URLSearchParams(document.location.search);
        params.delete('modal');

        router(`${thisPathname}?${params.toString()}`);
    };
    const handleOpenModalImageGallery = () => {
        router(`${thisPathname}?modal=PHOTO_TOUR_SCROLLABLE`);
    };

    const YoutubePlayer = useCallback(({ title, url }: { title: string; url: string }) => {
        return (
            <div className="aspect-w-16 aspect-h-full ">
                <iframe
                    src={`https://www.youtube.com/embed/${url}?autoplay=1`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title={title}
                ></iframe>
            </div>
        );
    }, []);

    const renderMainVideo = () => {
        return (
            <div className="">
                {isSafariBrowser() && !isPlay && !!!youtubeHash && (
                    <div
                        className="absolute inset-0 z-10 cursor-pointer "
                        onClick={() => setIsPlay(true)}
                    >
                        <Image
                            src={singlePost?.image?.original}
                            className="object-cover"
                            sizes="(max-width: 1024px) 100vw, 50vw"
                            alt="single"
                        />
                        <div className="absolute inset-0 flex items-center justify-center">
                            <NcPlayIcon />
                        </div>
                    </div>
                )}
                {!!youtubeHash ? (
                    <YoutubePlayer
                        title={singlePost?.post_title}
                        url={youtubeHash}
                    />
                ) : (
                    <ReactPlayer
                        url={singlePost?.video_url}
                        className="absolute inset-0"
                        playing={isSafariBrowser() ? isPlay : true}
                        width="100%"
                        height="100%"
                        controls
                        light={
                            isSafariBrowser()
                                ? false
                                : (singlePost?.thumbnail_image?.original ?? singlePost?.image?.original)
                        }
                        playIcon={<NcPlayIcon />}
                    />
                )}
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className={`nc-SingleHeader `}>
                <div className="space-y-5 dark text-neutral-100">
                    <CategoryBadgeList
                        itemClass="!px-3"
                        categories={singlePost?.categories}
                    />
                    <SingleTitle
                        mainClass="text-neutral-900 font-semibold text-3xl md:!leading-[120%] dark:text-neutral-100"
                        title={singlePost?.post_title}
                    />

                    <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
                    <div className="flex flex-col space-y-5">
                        <PostMetaUser
                            size="large"
                            className="leading-none flex-shrink-0"
                            hiddenCategories
                            avatarRounded="rounded-full shadow-inner"
                            meta={singlePost}
                        />
                        <SingleMetaAction2 post={singlePost} />
                    </div>
                </div>
            </div>
        );
    };

    if (singlePost?.errors?.length > 0)
        return (
            <div className="bg-white w-6/12 mx-auto my-10 p-4 rounded-md">
                <h4 className="text-xl text-center my-10 relative flex-grow">İçerik bulunamadı!</h4>
            </div>
        );
    return (
        <Layout>
            {url.startsWith('/post-video') && (
                <>
                    <header className="container relative py-14 lg:py-20 flex flex-col lg:flex-row lg:items-center">
                        <div className="nc-PageSingleVideo__headerWrap absolute inset-y-0 transform translate-x-1/2 right-1/2 w-screen lg:translate-x-0 lg:w-[calc(100vw/2)] bg-neutral-900 dark:bg-black dark:bg-opacity-50 lg:rounded-r-[40px]"></div>
                        <div className="pb-10 lg:pb-0 lg:pr-10 relative">{renderHeader()}</div>
                        <div className="relative lg:w-8/12 flex-shrink-0">
                            <div className="aspect-w-16 aspect-h-16 sm:aspect-h-9 border-4 border-neutral-300 dark:border-neutral-800 shadow-2xl bg-neutral-800 rounded-3xl overflow-hidden z-0">
                                {renderMainVideo()}
                            </div>
                        </div>
                    </header>
                    <div className="container mt-10">
                        <SingleContent
                            item={singlePost}
                            key={0}
                        />
                    </div>
                </>
            )}
            {url.startsWith('/post-photo') && (
                <>
                    <div className={`nc-SingleHeader4 mt-5`}>
                        <div className="max-w-5xl mx-auto space-y-5 dark">
                            <CategoryBadgeList
                                itemClass="!px-3"
                                categories={singlePost?.categories}
                            />
                            <SingleTitle
                                mainClass="text-neutral-900 font-bold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl 2xl:text-6xl"
                                title={singlePost?.post_title}
                            />
                            {singlePost?.post_excerpt && (
                                <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                                    {singlePost?.post_excerpt}
                                </span>
                            )}
                        </div>
                        <div className="container rounded-xl">
                            <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-2 my-10">
                                <div
                                    className="col-span-2 row-span-2 relative rounded-xl overflow-hidden cursor-pointer z-0"
                                    onClick={handleOpenModalImageGallery}
                                >
                                    {singlePost?.gallery && (
                                        <NcImage
                                            alt="single"
                                            containerClassName="max-w-[1320px] relative h-[650px] mx-auto blog-detail-image w-full"
                                            className="object-cover lg:object-cover rounded-3xl object-center"
                                            fill
                                            src={singlePost?.gallery[0]?.original ?? ''}
                                        />
                                    )}
                                    <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                                </div>
                                {singlePost?.gallery?.map((item: any, index: number) => (
                                    <>
                                        {index > 0 && (
                                            <div
                                                key={index}
                                                className={`relative rounded-xl overflow-hidden z-0 ${
                                                    index >= 2 ? 'hidden sm:block' : ''
                                                }`}
                                            >
                                                <NcImage
                                                    alt="single"
                                                    fill
                                                    containerClassName="max-w-[1320px] min-h-[300px] relative h-full mx-auto blog-detail-image w-full"
                                                    className="object-cover lg:object-cover rounded-3xl object-center"
                                                    src={item?.original ?? ''}
                                                />

                                                {/* OVERLAY */}
                                                <div
                                                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                                    onClick={handleOpenModalImageGallery}
                                                />
                                            </div>
                                        )}
                                    </>
                                ))}
                                <div
                                    className="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-full bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                                    onClick={handleOpenModalImageGallery}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={1.5}
                                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                        />
                                    </svg>
                                    <span className="ml-2 text-neutral-800 text-sm font-medium">
                                        Tüm fotoğrafları göster
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/*{JSON.stringify(singlePost?.gallery)}
                        {singlePost?.gallery.map(
                            (item: any, index: number) => (
                                <div
                                    key={index}
                                    className={`relative rounded-xl overflow-hidden z-0 ${
                                        index >= 2 ? "hidden sm:block" : ""
                                    }`}
                                >
                                    <NcImage
                                        alt="single"
                                        fill
                                        containerClassName="aspect-w-6 aspect-h-5"
                                        className="object-cover w-full h-full rounded-xl"
                                        src={item?.original || ""}
                                        sizes="(max-width: 1024px) 100vw, 1280px"
                                    />

                                     OVERLAY
                                    <div
                                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                        // onClick={handleOpenModalImageGallery}
                                    />
                                </div>
                            )
                        )}*/}
                        <div className="container mt-10">
                            <SingleContent
                                item={singlePost}
                                key={1}
                            />
                        </div>
                    </div>
                    <ListingImageGallery
                        isShowModal={modal === 'PHOTO_TOUR_SCROLLABLE' && singlePost?.gallery?.length > 0}
                        onClose={handleCloseModalImageGallery}
                        images={singlePost?.gallery?.map((item: any, index: number) => {
                            return {
                                id: index,
                                url: item?.original ?? '',
                            };
                        })}
                    />
                </>
            )}
            {url.startsWith('/post-textual') && (
                <>
                    <div className={`nc-SingleHeader4 mt-5 p-4`}>
                        <div className="max-w-5xl mx-auto space-y-5 dark">
                            <CategoryBadgeList
                                itemClass="!px-3"
                                categories={singlePost?.categories}
                            />
                            <SingleTitle
                                mainClass="text-neutral-900 font-bold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl 2xl:text-6xl"
                                title={singlePost?.post_title}
                            />
                            {singlePost?.post_excerpt && (
                                <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                                    {singlePost?.post_excerpt}
                                </span>
                            )}
                        </div>
                        {singlePost?.thumbnail_image && (
                            <NcImage
                                alt={singlePost.post_title}
                                containerClassName="max-w-[1320px] my-10 sm:my-12 relative h-[650px] mx-auto blog-detail-image w-full"
                                className="object-cover lg:object-contain rounded-3xl object-center"
                                fill
                                src={singlePost?.thumbnail_image?.original}
                            />
                        )}
                        <div className="container mt-10">
                            <SingleContent
                                item={singlePost}
                                key={2}
                            />
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};

export default PageSinglePostVideo;
