import { UpdateUser } from "framework/rest/types";
import { useMutation, useQueryClient } from "react-query";
import User from "../types/user";
import { API_ENDPOINTS } from "utils/endpoints";
import { toast } from 'react-toastify';

export interface IUserUpdateVariables {
    variables: { id: number; input: UpdateUser };
  }
  
  export const useUpdateUserMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({ variables: { id, input } }: IUserUpdateVariables) =>
        User.update(`${API_ENDPOINTS.USERS}/${id}`, input),
      {
        onSuccess: () => {
          toast.success("Başarıyla güncellendi");
        },

        onError: () => {
          toast.error("Güncelleme başarısız");
        },
        // Always refetch after error or success:
        onSettled: () => {
          queryClient.invalidateQueries(API_ENDPOINTS.ME);
        },
      }
    );
  };
  