import CategoryBadgeList from 'components/CategoryBadgeList/CategoryBadgeList';
import Link from 'components/Link';
import { FC } from 'react';
import NcImage from '../NcImage/NcImage';
import PostCardLikeAndComment from '../PostCardLikeAndComment/PostCardLikeAndComment';
import PostCard5EditMeta from '../PostCardMeta/PostCard5EditMeta';
import PostCardSaveAction from '../PostCardSaveAction/PostCardSaveAction';
import PostTypeFeaturedIcon from '../PostTypeFeaturedIcon/PostTypeFeaturedIcon';

export interface Card5Props {
    className?: string;
    post: any;
}

const Card5: FC<Card5Props> = ({ className = '', post }) => {
    const { title, categories, readingTime } = post;
    return (
        <Link
            onClick={e => {
                const target = e.target as HTMLElement;

                if (target !== e.currentTarget) {
                    if (['A', 'BUTTON', 'svg', 'SPAN', 'path'].includes(target?.nodeName!)) {
                        e.preventDefault();
                    }
                }
            }}
            href={
                (post?.type === 'video' ? 'post-video' : post?.type === 'photo' ? 'post-photo' : 'post-textual') +
                '/' +
                post?.id
            }
            className="w-full"
        >
            <div
                className={`nc-Card3 relative flex sm:items-center group p-2 sm:p-5 2xl:p-5 border border-neutral-200 !rounded-lg sm:!rounded-3xl h-full ${className}`}
            >
                <div className="flex flex-col flex-1">
                    <div className="space-y-2 sm:space-y-3.5 sm:mb-4">
                        <CategoryBadgeList categories={categories} />
                        <div className="block">
                            <h2
                                className={`block font-medium sm:font-semibold text-neutral-900 dark:text-neutral-100 text-sm sm:text-base xl:text-xl`}
                            >
                                <span
                                    className="line-clamp-2"
                                    title={title}
                                >
                                    {post.post_title}
                                </span>
                            </h2>
                            <span className="hidden sm:block sm:mt-2">
                                <span className="text-neutral-500 dark:text-neutral-400 text-base line-clamp-1">
                                    {post.post_excerpt}
                                </span>
                            </span>
                        </div>

                        <PostCard5EditMeta meta={{ ...post }} />
                    </div>
                    <div className="hidden sm:flex rtl:sm:flex-row-reverse items-center flex-wrap justify-between mt-auto">
                        <PostCardLikeAndComment />
                        <PostCardSaveAction
                            readingTime={readingTime}
                            post={post}
                        />
                    </div>
                </div>
                <div
                    className={`block flex-shrink-0 ms-2.5 sm:ms-5 w-4/12 max-w-[120px] sm:max-w-none sm:w-44 2xl:w-56 ml-5`}
                >
                    <div className="w-full block h-0 aspect-h-16 aspect-w-16 rounded-lg sm:rounded-2xl overflow-hidden z-0">
                        {post?.image && (
                            <NcImage
                                containerClassName="absolute inset-0 group-hover:scale-110 transform transition-transform duration-300"
                                src={post?.image?.original ?? post?.image?.thumbnail}
                                fill
                                alt={title}
                            />
                        )}
                        {/* <span>
                            <PostTypeFeaturedIcon
                                className="absolute left-2 bottom-2"
                                postType={post?.type}
                                wrapSize="w-8 h-8"
                                iconSize="w-4 h-4"
                            />
                        </span> */}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default Card5;
