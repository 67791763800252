import {
  PostPaginator,
  PostsQueryOptionsType,
  QueryParamsType,
} from "framework/rest/types";
import { pickBy } from "lodash";
import { useQuery } from "react-query";
import { CoreApi } from "utils/core-api";
import { mapPaginatorData } from "utils/data-mappers";
import { API_ENDPOINTS } from "utils/endpoints";
import postInstance from "./post";

const PostService = new CoreApi(API_ENDPOINTS.GET_POST);
export const stringifySearchQuery = (values: any) => {
  const parsedValues = pickBy(values);
  return Object.keys(parsedValues)
    .map((k) => {
      if (k === "type") {
        return `${k}.slug:${parsedValues[k]};`;
      }
      if (k === "post") {
        return `posts.slug:${parsedValues[k]};`;
      }
      if (k === "tags") {
        return `tags.slug:${parsedValues[k]};`;
      }
      return `${k}:${parsedValues[k]};`;
    })
    .join("")
    .slice(0, -1);
};

const fetchPosts = async ({
  queryKey,
}: QueryParamsType): Promise<{ posts: PostPaginator }> => {
  const [, params] = queryKey as [string, PostsQueryOptionsType];

  const {
    page,
    post_title,
    post_excerpt,
    tags,
    limit = 15,
    orderBy = "created_at",
    sortedBy = "DESC",
    parent = null,
    featured = null,
  } = params as PostsQueryOptionsType;

  const searchString = stringifySearchQuery({
    name: post_title,
    type: post_excerpt,
    tags: tags
  });
  // @ts-ignore
  const queryParams = new URLSearchParams({
    searchJoin: "and",
    orderBy,
    sortedBy,
    parent,
    limit: limit.toString(),
    featured,
    ...(page && { page: page.toString() }),
    ...(Boolean(searchString) && { search: searchString }),
  });
  const url = `${API_ENDPOINTS.GET_POST}?${queryParams.toString()}`;
  const {
    data: { data, ...rest },
  } = await postInstance.all(url);
  return {
    posts: {
      data,
      paginatorInfo: {
        ...mapPaginatorData({ ...rest }),
        count: rest.total,
        currentPage: rest.current_page,
        lastPage: rest.last_page,
        perPage: rest.per_page,
        total: rest.total,
      },
    },
  };
};

const usePostsQuery = (options: {}) => {
  return useQuery<{ posts: PostPaginator }, Error>(
    [API_ENDPOINTS.GET_POST, options],
    fetchPosts,
    {
      keepPreviousData: true,
    }
  );
};

export const fetchPost = async (params: string | number) => {
  const { data } = await PostService.findOne(params);

  return data;
};

export { fetchPosts, usePostsQuery };

