import { ArrowSmallRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import ButtonCircle from 'components/Button/ButtonCircle';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import Card11 from 'components/Card11/Card11';
import Heading from 'components/Heading/Heading';
import Input from 'components/Input/Input';
import Nav from 'components/Nav/Nav';
import NavItem from 'components/NavItem/NavItem';
import NcImage from 'components/NcImage/NcImage';
import NcLink from 'components/NcLink/NcLink';
import { usePostsQuery } from 'framework/rest/post/posts.query';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import featuresBg from '../../../images/features.png';
import referencesBg from '../../../images/references.png';

function useSearchParams() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

type PageDataType = {
    image: string;
    title: string;
    subTitle: string;
};

const PageSearch = () => {
    const [pageData, setPageData] = useState<PageDataType>();
    let query = useSearchParams();

    const { data: posts } = usePostsQuery({
        limit: 999,
        tags: query.get('name') === 'references' ? 'references' : 'features',
    });

    const { data: subPosts } = usePostsQuery({
        limit: 999,
        tags: query.get('name') === 'references' ? 'features' : 'references',
    });

    const [tabActive, setTabActive] = useState(0);

    const handleClickTab = (item: number) => {
        if (item === tabActive) {
            return;
        }
        setTabActive(item);
    };

    useEffect(() => {
        switch (query.get('name')) {
            case 'references':
                setPageData({
                    image: referencesBg,
                    title: 'MUTLU DENEYİMLER',
                    subTitle: 'Profesyonellerin web altyapısı',
                });
                break;
            case 'features':
                setPageData({
                    image: featuresBg,
                    title: 'AVANTAJLAR',
                    subTitle: 'Profesyonellerin web altyapısı',
                });
                break;
            default:
                setPageData({
                    image: 'https://images.pexels.com/photos/2138922/pexels-photo-2138922.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                    title: 'Technology',
                    subTitle: 'We found 1135 results for Technology',
                });
                break;
        }
    }, [query]);

    return (
        <div className={`nc-PageSearch`}>
            {/* HEADER */}
            <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto">
                <div className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-9 lg:aspect-h-5 overflow-hidden z-0">
                    <NcImage
                        alt="search"
                        fill
                        containerClassName="absolute inset-0"
                        src={pageData?.image}
                        className="object-cover w-full h-full"
                        sizes="(max-width: 1280px) 100vw, 1536px"
                    />
                </div>
                {/* CONTENT */}
                <div className="relative container -mt-20 lg:-mt-48">
                    <div className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex items-center">
                        <header className="w-full max-w-3xl mx-auto text-center flex flex-col items-center">
                            <h2 className="text-2xl sm:text-4xl font-semibold">{pageData?.title}</h2>
                            <span className="block text-xs sm:text-sm mt-4 text-neutral-500 dark:text-neutral-300">
                                {pageData?.subTitle}
                            </span>
                            <form
                                className="relative w-full mt-8 sm:mt-11 text-left"
                                method="post"
                            >
                                <label
                                    htmlFor="search-input"
                                    className="text-neutral-500 dark:text-neutral-300"
                                >
                                    <span className="sr-only">Search all icons</span>
                                    <Input
                                        id="search-input"
                                        type="search"
                                        placeholder="Ara"
                                        sizeClass="pl-14 py-5 pr-5 md:pl-16"
                                        defaultValue={''}
                                    />
                                    <ButtonCircle
                                        className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                                        size=" w-11 h-11"
                                        type="submit"
                                    >
                                        <ArrowSmallRightIcon className="w-6 h-6" />
                                    </ButtonCircle>
                                    <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                                        <svg
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                                d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                                            ></path>
                                        </svg>
                                    </span>
                                </label>
                            </form>
                            <div className="w-full text-sm text-left mt-4 text-neutral-500 dark:text-neutral-300">
                                <div className="inline-block space-x-1.5 sm:space-x-2.5">
                                    <span className="">Hızlı Ara:</span>
                                    <NcLink
                                        className="inline-block font-normal"
                                        href="/search?name=references"
                                    >
                                        Referanslar
                                    </NcLink>
                                    <NcLink
                                        className="inline-block font-normal"
                                        href="/search?name=features"
                                    >
                                        Özellikler
                                    </NcLink>
                                    {/* <NcLink className="inline-block font-normal" href="/search">
                                        Vector
                                    </NcLink>
                                    <NcLink className="inline-block font-normal" href="/search">
                                        Frontend
                                    </NcLink> */}
                                </div>
                            </div>
                        </header>
                    </div>
                </div>
            </div>
            {/* ====================== END HEADER ====================== */}

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
                <main>
                    {/* TABS FILTER */}
                    <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
                        <Nav
                            containerClassName="w-full overflow-x-auto hiddenScrollbar"
                            className="sm:space-x-2"
                        >
                            <NavItem
                                isActive={0 === tabActive}
                                onClick={() => handleClickTab(0)}
                            >
                                Tümü
                            </NavItem>
                            {posts?.posts.data
                                .filter(x => x.featured === 1)
                                ?.map(x => x.categories)
                                .flat()
                                .filter(x => x.featured === 1)
                                .map((category, index) => {
                                    const newName = category?.name?.includes('_')
                                        ? category?.name.split('_')[0].trim()
                                        : category.name;
                                    return (
                                        <NavItem
                                            isActive={category.id === tabActive}
                                            key={index}
                                            onClick={() => handleClickTab(category?.id as number)}
                                        >
                                            {newName}
                                        </NavItem>
                                    );
                                })}
                        </Nav>
                        <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
                        <div className="flex justify-end">
                            <Link
                                to={`${process.env.REACT_APP_START_URL}/explore`}
                                className="flex flex-row"
                            >
                                <ButtonPrimary pattern="primary">
                                    <span>Hemen Sitenizi Açın</span>
                                    <ChevronDoubleRightIcon className="h-6 w-6 ml-4" />
                                </ButtonPrimary>
                            </Link>
                        </div>
                    </div>

                    {/* LOOP ITEMS */}
                    {/* LOOP ITEMS POSTS */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
                        {posts?.posts.data
                            .filter(x => x.categories.map(y => y.id).includes(tabActive) || tabActive === 0)
                            .filter(x => x.categories.find(x => x.featured === 1))
                            .map(post => (
                                <Card11
                                    key={post.id}
                                    post={post}
                                />
                            ))}
                    </div>
                    {/* LOOP ITEMS CATEGORIES */}

                    {/* PAGINATION */}
                    {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                        <Pagination />
                        <ButtonPrimary>Show me more</ButtonPrimary>
                    </div> */}
                </main>

                {/* MORE SECTIONS */}
                {/* === SECTION 5 === */}
                <div className="relative py-16">
                    <BackgroundSection />
                    <Heading
                        desc={'Profesyonellerin web altyapısı'}
                        isCenter={true}
                    >
                        {query.get('name') === 'references' ? 'AVANTAJLAR' : 'MUTLU DENEYİMLER'}
                    </Heading>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
                        {subPosts?.posts.data.map(post => (
                            <Card11
                                key={post.id}
                                post={post}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageSearch;
