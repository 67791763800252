import { useQuery } from "react-query";
import { CoreApi } from "utils/core-api";
import { API_ENDPOINTS } from "utils/endpoints";

const settingsService = new CoreApi(API_ENDPOINTS.SITE_SETTINGS);

export const fetchSettings = async () => {
  const data = await settingsService.findAll();
  return data;
};
export const useSettingsQuery = (lang:any) => {
  return useQuery(`${API_ENDPOINTS.SITE_SETTINGS}?${lang}`, fetchSettings);
};
