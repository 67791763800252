import CardCategory1 from 'components/CardCategory1/CardCategory1';
import ModalsContainer from 'components/Modal/ModalContainer';
import StoreRequestModal from 'components/Modal/StoreRequestModal';
import WidgetHeading1 from 'components/WidgetHeading1/WidgetHeading1';
import { DEMO_CATEGORIES } from 'data/taxonomies';
import { TaxonomyType } from 'data/types';
import { useCategoriesQuery } from 'framework/rest/category/categories.query';
import { Category } from 'framework/rest/types';
import { FC, useState } from 'react';

const categoriesDemo: TaxonomyType[] = DEMO_CATEGORIES.filter((_, i) => i > 7 && i < 13);
export interface WidgetCategoriesProps {
    className?: string;
    categories?: TaxonomyType[];
}

const WidgetCategories: FC<WidgetCategoriesProps> = ({
    className = 'bg-neutral-100 dark:bg-neutral-800',
    categories = categoriesDemo,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Category>();
    const { data, isLoading } = useCategoriesQuery({
        limit: 5,
    });

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}>
            <WidgetHeading1
                title="Uzmanlar"
                viewAll={{ label: '', href: '/#' }}
            />
            <div className="flow-root">
                <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
                    {data?.categories.data.map(category => {
                        const newName = category.name.includes('_')
                            ? category.name.split('_')[1].trim()
                            : category.name;

                        const sector = category.name.includes('_') ? category.name.split('_')[0].trim() : category.name;

                        return (
                            <CardCategory1
                                className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                                key={category.id}
                                image={category.image}
                                name={newName}
                                data={category}
                                size="normal"
                                subTitle={sector}
                                onClick={() => {
                                    setSelectedItem(category);
                                    setIsOpen(true);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <ModalsContainer
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            >
                <StoreRequestModal
                    item={selectedItem!}
                    setIsOpen={setIsOpen}
                />
            </ModalsContainer>
        </div>
    );
};

export default WidgetCategories;
