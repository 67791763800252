import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import Logo from 'components/Logo/Logo';
import MenuBar from 'components/MenuBar/MenuBar';
import Navigation from 'components/Navigation/Navigation';
import { FC } from 'react';
import AvatarDropdown from './AvatarDropdown';

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
    const renderContent = () => {
        return (
            <div className="h-20 flex justify-between">
                <div className="lg:flex-1 flex items-center">
                    <Logo />
                </div>

                <div className="flex-[2] hidden lg:flex justify-center mx-4">
                    <Navigation />
                </div>

                <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100 gap-2">
                    <AvatarDropdown />
                    <div className="hidden lg:block">
                        <ButtonPrimary
                            href={`${process.env.REACT_APP_START_URL}/explore`}
                            className="!px-5 !py-2"
                        >
                            <span className="whitespace-nowrap">Tasarımları Keşfet</span>
                            <ChevronDoubleRightIcon className="h-4 w-4 ml-2" />
                        </ButtonPrimary>
                    </div>
                    {/* <SearchModal /> */}
                    {/* <NotifyDropdown /> */}
                </div>

                <div className="flex items-center lg:hidden">
                    <MenuBar />
                </div>
            </div>
        );
    };

    return (
        <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
            <div className="container ">{renderContent()}</div>
        </div>
    );
};

export default MainNav2Logged;
