import ButtonPrimary from "components/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";
import Input from "components/Input/Input";
import NcLink from "components/NcLink/NcLink";
import Layout from "../layout";

const PageForgotPass = () => {
    return (
        <Layout>
            <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
                <Heading2>Şifremi Unuttum</Heading2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                    Kayıt olabilir veya giriş yapabilirsiniz.
                </span>
            </header>

            <div className="max-w-md mx-auto space-y-6">
                {/* FORM */}
                <form className="grid grid-cols-1 gap-6" action="#" method="post">
                    <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">E-posta adresi</span>
                        <Input type="email" placeholder="mail@adresiniz.com" className="mt-1" />
                    </label>
                    <ButtonPrimary type="submit">Devam Et</ButtonPrimary>
                </form>

                {/* ==== */}
                <span className="block text-center text-neutral-700 dark:text-neutral-300">
                    <NcLink href="/login">Giriş Yap</NcLink>
                    {` veya `}
                    <NcLink href="/signup">Kayıt Ol</NcLink>
                </span>
            </div>
        </Layout>
    );
};

export default PageForgotPass;
