import {useQuery} from "react-query";
import {CoreApi} from "utils/core-api";
import {API_ENDPOINTS} from "utils/endpoints";

const settingsService = new CoreApi(API_ENDPOINTS.SETTINGS);

export const fetchSettings = async () => {
  return await settingsService.findAll();
};
export const useGenaralSettingsQuery = (lang:any) => {
  return useQuery(`${API_ENDPOINTS.SETTINGS}?${lang}`, fetchSettings);
};
