import { FC } from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn, FaXTwitter } from 'react-icons/fa6';
import { FiLink } from 'react-icons/fi';
export interface SocialsShareProps {
    className?: string;
    itemClass?: string;
}

export interface SocialType {
    id: string;
    name: string;
    icon: JSX.Element;
    url: string;
}

const socials: SocialType[] = [
    {
        id: 'Kopyala',
        name: 'Linki Kopyala',
        icon: <FiLink size={20} />,
        url: `#`,
    },
    {
        id: 'Facebook',
        name: 'Facebook',
        icon: <FaFacebookF size={20} />,
        url: `https://www.facebook.com/sharer/sharer.php?u={{url}}&t={{title}}`,
    },
    {
        id: 'Twitter',
        name: 'Twitter',
        icon: <FaXTwitter size={20} />,
        url: `https://twitter.com/intent/tweet?url={{url}}&text={{title}}`,
    },
    {
        id: 'Linkedin',
        name: 'Linkedin',
        icon: <FaLinkedinIn size={20} />,
        url: 'https://www.linkedin.com/sharing/share-offsite/?url={{url}}&title={{title}}',
    },
    {
        id: 'Instagram',
        name: 'Instagram',
        icon: <FaInstagram size={20} />,
        url: 'https://www.instagram.com/?url={{url}}&title={{title}}',
    },
];

export const SOCIALS_DATA = socials;

const SocialsShare: FC<SocialsShareProps> = ({
    className = 'grid gap-[6px]',
    itemClass = 'w-7 h-7 text-base hover:bg-neutral-100',
}) => {
    const renderItem = (item: SocialType, index: number) => {
        return (
            <a
                key={index}
                href={item.url}
                className={`rounded-full leading-none flex items-center justify-center text-neutral-6000 ${itemClass} ${index === 0 ? 'divide-y divide-neutral-500' : ''}`}
                title={`Share on ${item.id}`}
            >
                <div>{item.icon}</div>
            </a>
        );
    };

    return (
        <div
            className={`nc-SocialsShare ${className}`}
            data-nc-id="SocialsShare"
        >
            {socials.map(renderItem)}
        </div>
    );
};

export default SocialsShare;
