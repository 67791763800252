import { useMutation } from 'react-query';
import { CoreApi } from 'utils/core-api';
import { API_ENDPOINTS } from 'utils/endpoints';

const StoreRequestService = new CoreApi(API_ENDPOINTS.CONTACT_US);

export interface IStoreRequestCreate {
    fullname: string;
    email: string;
    phone: string;
    category_data: {
        name: string;
    };
}

export const useStoreCreateMutation = () => {
    return useMutation((data: IStoreRequestCreate) => {
        console.log(data);
        return StoreRequestService.postUrl(`${API_ENDPOINTS.CONTACT_US}`, data);
    });
};
