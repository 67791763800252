import PageArchiveAudio from "app/(archives)/archive-2/page";
import PageArchiveVideo from "app/(archives)/archive-3/page";
import PageArchive from "app/(archives)/archive/page";
import CategoryPage from "app/(category)/category";
import PageHomeDemo2 from "app/(home)/home-2/page";
import PageHomeDemo3 from "app/(home)/home-3/page";
import PageHomeDemo4 from "app/(home)/home-4/page";
import PageHomeDemo6 from "app/(home)/home-6/page";
import PageHome from "app/(home)/page";
import PageContact from "app/(others)/contact/page";
import DashboardBilingAddress from "app/(others)/dashboard/billing-address/page";
import DashboardEditProfile from "app/(others)/dashboard/edit-profile/page";
import DashboardPosts from "app/(others)/dashboard/posts/page";
import DashboardSubmitPost from "app/(others)/dashboard/submit-post/page";
import DashboardSubcription from "app/(others)/dashboard/subscription/page";
import PageForgotPass from "app/(others)/forgot-pass/page";
import PageLogin from "app/(others)/login/page";
import PageSignUp from "app/(others)/signup/page";
import PageSubcription from "app/(others)/subscription/page";
import PageSearchV2 from "app/(search)/search-2/page";
import PageSearch from "app/(search)/search/page";
import PageSinglePostVideo from "app/(singles)/(default)/post-video/page";
import PageSingleTemplate2 from "app/(singles)/(default)/single-2/page";
import PageSingleAudio from "app/(singles)/(default)/single-audio/page";
import PageSingleGallery from "app/(singles)/(default)/single-gallery/page";
import PageSingleVideo from "app/(singles)/(default)/single-video/page";
import PageSingle from "app/(singles)/(default)/single/page";
import PageSingleTemplate3 from "app/(singles)/(has-sidebar)/single-3/page";
import PageSingleTemplate4 from "app/(singles)/(has-sidebar)/single-4/page";
import TagPage from "app/(tag)/tag";
import SiteHeader from "app/SiteHeader";
import PageAbout from "app/about/page";
import PageAuthor from "app/author/page";
import Page404 from "app/not-found";
import Footer from "components/Footer/Footer";
import MusicPlayer from "components/MusicPlayer/MusicPlayer";
import { useGenaralSettingsQuery } from "framework/rest/general-settings/use-general-settings";
import { useSettingsQuery } from "framework/rest/site-settings/use-settings";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { generalSettingsAtom } from "store/atom/general-settings";
import { settingsAtom } from "store/atom/settings.atom";
import ComingSoon from "../components/ComingSoon/coming-soon";
import { Page } from "./types";

export const pages: Page[] = [
    { path: "/", component: PageHome },
    { path: "/#", component: PageHome },
    { path: "/home-2", component: PageHomeDemo2 },
    { path: "/home-3", component: PageHomeDemo3 },
    { path: "/home-4", component: PageHomeDemo4 },
    { path: "/home-6", component: PageHomeDemo6 },

    // archives page -------------------------------------------------------
    { path: "/archive/*", component: PageArchive },
    { path: "/archive-2/*", component: PageArchiveAudio },
    { path: "/archive-3/*", component: PageArchiveVideo },
    { path: "/author/*", component: PageAuthor },

    // single page -------------------------------------------------------
    { path: "/single/*", component: PageSingle },
    { path: "/single-2/*", component: PageSingleTemplate2 },
    { path: "/single-audio/*", component: PageSingleAudio },
    { path: "/single-video/*", component: PageSingleVideo },
    { path: "/post-video/:id", component: PageSinglePostVideo },
    { path: "/post-photo/:id", component: PageSinglePostVideo },
    { path: "/post-textual/:id", component: PageSinglePostVideo },
    { path: "/single-gallery/*", component: PageSingleGallery },
    { path: "/single-3/*", component: PageSingleTemplate3 },
    { path: "/single-4/*", component: PageSingleTemplate4 },
    { path: "/single-5/*", component: PageSingleTemplate2 },

    // category page -------------------------------------------------------
    { path: "/category/*", component: CategoryPage },

    // tag page -------------------------------------------------------
    { path: "/tag/*", component: TagPage },

    // search -------------------------------------------------------
    { path: "/search", component: PageSearch },
    { path: "/search-2", component: PageSearchV2 },

    // other pages -------------------------------------------------------
    { path: "/about", component: PageAbout },
    { path: "/contact", component: PageContact },
    { path: "/page404", component: Page404 },
    { path: "/login", component: PageLogin },
    { path: "/signup", component: PageSignUp },
    { path: "/forgot-pass", component: PageForgotPass },
    { path: "/subscription", component: PageSubcription },
    { path: "/dashboard", component: DashboardSubmitPost },
    { path: "/dashboard/posts", component: DashboardPosts },
    { path: "/dashboard/edit-profile", component: DashboardEditProfile },
    { path: "/dashboard/subscription", component: DashboardSubcription },
    { path: "/dashboard/billing-address", component: DashboardBilingAddress },
    { path: "/dashboard/submit-post", component: DashboardSubmitPost },
];

const MyRoutes = () => {
    const { data, isLoading } = useSettingsQuery("en");
    const { data: generalSettings } = useGenaralSettingsQuery("en");
    const [, setSettings] = useAtom(settingsAtom);
    const [, setGenaralSettings] = useAtom(generalSettingsAtom);

    useEffect(() => {
        setSettings(data?.data);
        setGenaralSettings(generalSettings?.data?.options);
    }, [data?.data, setSettings, generalSettings?.data, setGenaralSettings]);

    return (
        <>
            {!isLoading &&
                (data?.data.developing_mode.is_active ? (
                    <>
                        <ComingSoon developingMode={data?.data.developing_mode} />
                    </>
                ) : (
                    <>
                        <BrowserRouter>
                            <SiteHeader />

                            <Routes>
                                {pages.map(({ component: Component, path }, index) => {
                                    return <Route key={index} element={<Component />} path={path} />;
                                })}
                                <Route element={<Page404 />} />
                            </Routes>

                            <Footer />
                            <MusicPlayer />
                        </BrowserRouter>
                    </>
                ))}
        </>
    );
};

export default MyRoutes;
