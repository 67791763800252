import CategoryBadgeList from 'components/CategoryBadgeList/CategoryBadgeList';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import Link from 'components/Link';
import PostCardMetaV2 from 'components/PostCardMeta/PostCardMetaV2';
import PostCardSaveAction from 'components/PostCardSaveAction/PostCardSaveAction';
import PostFeaturedMedia from 'components/PostFeaturedMedia/PostFeaturedMedia';
import { PostDataType } from 'data/types';
import { FC, useState } from 'react';

export interface Card10V2Props {
    className?: string;
    post: PostDataType;
}

const Card10V2: FC<Card10V2Props> = ({ className = 'h-full', post }) => {
    const [isShow, setIsShow] = useState(false);
    const { href, categories, type, id, youtubeSlug } = post;
    const [isHover, setIsHover] = useState(false);
    let url = '#';

    if (type === 'textual') {
        url = `/post-textual/${id}`;
    } else if (type === 'photo') {
        url = `/post-photo/${id}`;
    } else if (type === 'video') {
        url = `/post-video/${id}`;
    }

    return (
        <div
            className={`nc-Card10V2 relative flex flex-col ${className}`}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <div
                className="block group rounded-3xl flex-shrink-0 relative w-full aspect-w-16 aspect-h-12 sm:aspect-h-9 overflow-hidden z-0"
                onClick={() => {
                    setIsShow(true);
                }}
            >
                <div>
                    <PostFeaturedMedia
                        post={post}
                        isHover={isHover}
                    />
                </div>

                <Link
                    href={href ?? url}
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 transition-opacity"
                ></Link>
            </div>
            <div className="absolute top-3 inset-x-3 flex justify-between items-start space-x-4">
                <CategoryBadgeList categories={categories} />
                <PostCardSaveAction post={post} />
            </div>

            <div className="space-y-2.5 mt-4 px-4">
                <PostCardMetaV2 meta={post} />
            </div>
            {youtubeSlug && (
                <DialogPopup
                    youtubeSlug={youtubeSlug!}
                    isOpen={isShow}
                    setIsOpen={setIsShow}
                />
            )}
        </div>
    );
};

export default Card10V2;
