import React from "react";
import NcImage from "components/NcImage/NcImage";
import Pagination from "components/Pagination/Pagination";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import {usePostsQuery} from "../../../../framework/rest/post/posts.query";

const DashboardPosts = () => {
  const { data: posts, isLoading: loading } = usePostsQuery({});

  return (
    <Layout>
      <LayoutDashboard>
        <div className="flex flex-col space-y-8">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
              <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
                  <thead className="bg-neutral-50 dark:bg-neutral-800">
                    <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                      <th scope="col" className="px-6 py-3">
                      Paylaşımlar

                      </th>
                      <th scope="col" className="px-6 py-3">
                      Durum

                      </th>
                      <th scope="col" className="px-6 py-3">
                      Düzenle

                      </th>

                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Düzenle</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                    {posts?.posts?.data?.map((item: any, i) => (
                      <tr key={i}>
                        <td className="px-6 py-4">
                          <div className="flex items-center w-96 lg:w-auto max-w-md overflow-hidden">
                            {item.post_title}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.status === 'publish' ? (
                            <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-teal-100 text-teal-900 lg:text-sm">
                              Publish
                            </span>
                          ) : (
                            <span className="px-2 inline-flex text-sm text-neutral-500 dark:text-neutral-400 rounded-full">
                              Draft
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-neutral-300">
                          <a
                            href="/#"
                            className="text-primary-800 dark:text-primary-500 hover:text-primary-900"
                          >
                         Düzenle
                          </a>
                          {` | `}
                          <a
                            href="/#"
                            className="text-rose-600 hover:text-rose-900"
                          >
                           Sil
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/*<Pagination />*/}
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardPosts;
