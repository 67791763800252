/* eslint-disable @typescript-eslint/no-redeclare */
import ButtonPrimary from "components/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";
import Image from "components/Image";
import Input from "components/Input/Input";
import NcLink from "components/NcLink/NcLink";
import { useLoginMutation } from "framework/rest/auth/auth.query";
import googleSvg from "images/Google.svg";
import { useAtom } from "jotai";
import Cookies from "js-cookie";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { authorizationAtom } from "store/atom/auth";
import { settingsAtom } from "store/atom/settings.atom";
import { AUTH_TOKEN } from "utils/constants";
import Layout from "../layout";

const loginSocials = [
    // {
    //     name: "Continue with Facebook",
    //     href: "#",
    //     icon: facebookSvg,
    // },
    // {
    //     name: "Continue with Twitter",
    //     href: "#",
    //     icon: twitterSvg,
    // },
    {
        name: "Google ile Oturum Aç",
        href: "#",
        icon: googleSvg,
    },
];

interface LoginInputType {
    email: string;
    password: string;
}

const PageLogin = () => {
    const { mutate: login, isLoading: loading } = useLoginMutation();
    const router = useNavigate();
    const defaultValues = {
        email: "",
        password: "",
    };
    const { register, handleSubmit } = useForm({
        defaultValues,
    });
    const [, authorize] = useAtom(authorizationAtom);
    const [isError, setIsError] = useState(false);
    const [settings, _] = useAtom(settingsAtom);

    const onSubmit = async ({ email, password }: LoginInputType) => {
        setIsError(false);

        login(
            {
                email,
                password,
            },
            {
                onSuccess: (data) => {
                    if (data?.token && data?.permissions?.length) {
                        Cookies.set(AUTH_TOKEN, data.token);
                        authorize(true);
                        window.location.href = `${process.env.REACT_APP_START_URL}/explore`;
                    } else {
                        setIsError(true);
                    }
                },
                onError: (error: any) => {},
            }
        );
    };

    return (
        <Layout>
            <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
                <Heading2>Giriş</Heading2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                    Kayıt olabilir veya giriş yapabilirsiniz.
                </span>
            </header>

            <div className="max-w-md mx-auto space-y-6">
                {settings?.homepage?.login?.google && (
                    <>
                        <div className="grid gap-3">
                            {loginSocials.map((item, index) => (
                                <a
                                    key={index}
                                    href={item.href}
                                    className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                                >
                                    <Image className="flex-shrink-0" src={item.icon} alt={item.name} />
                                    <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                                        {item.name}
                                    </h3>
                                </a>
                            ))}
                        </div>
                        <div className="relative text-center">
                            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                                VEYA
                            </span>
                            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
                        </div>
                    </>
                )}
                {/* FORM */}
                <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                    <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">E-posta adresi</span>
                        <Input
                            type="email"
                            placeholder="example@example.com"
                            className="mt-1"
                            {...register("email", { required: true })}
                        />
                    </label>
                    <label className="block">
                        <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                            Şifre
                            <NcLink href="/forgot-pass" className="text-sm underline">
                                Şifreni mi unuttun?
                            </NcLink>
                        </span>
                        <Input type="password" className="mt-1" {...register("password", { required: true })} />
                    </label>
                    {isError && <div className="text-red-900 text-center">Geçersiz kullanıcı adı ve şifre</div>}
                    <ButtonPrimary type="submit">Devam</ButtonPrimary>
                </form>

                {/* ==== */}
                <span className="block text-center text-neutral-700 dark:text-neutral-300">
                    <span className="mr-2">Üye değil misin?</span>
                    <NcLink href="/signup">Hemen kayıt ol</NcLink>
                </span>
            </div>
        </Layout>
    );
};

export default PageLogin;
