import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import React from "react";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import useUser from "framework/rest/auth/use-user";
import { useForm } from "react-hook-form";
import { useUpdateUserMutation } from "framework/rest/auth/use-user-update.mutation";
import pick from "lodash/pick";
import FileInput from "components/file-input";
import Textarea from "components/Textarea/Textarea";

const DashboardEditProfile = () => {
  const { mutate: updateUser, isLoading: loading } = useUpdateUserMutation();
  const { me } = useUser();
  const {
    register,
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      ...(me &&
        pick(me, ["name", "email", "profile.bio", "profile.contact", "profile.avatar"])),
    },
  });

  async function onSubmit(values: any) {
    const { profile } = values;
    updateUser({
      variables: {
        id: me?.id,
        input: {
          name: values.name,
          profile: {
            id: me?.profile?.id,
            avatar: {
              id: profile?.avatar?.id,
              original: profile?.avatar?.original,
              thumbnail: profile?.avatar?.thumbnail,
            },
            bio: profile?.bio,
            contact: profile?.contact,
          },
        },
      },
    })
  }
  return (
    <Layout>
      <LayoutDashboard>
        <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
          <form
            className="grid md:grid-cols-2 gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label className="block md:col-span-2">
              <Label>
                Adı Soyadı
              </Label>
              <Input placeholder="Name"
                {...register("name")}
              type="text" className="mt-1" />
            </label>
            {/* <label className="block">
              <Label>Current password</Label>
              <Input placeholder="***" type="password" className="mt-1" />
            </label>
            <label className="block">
              <Label>New password</Label>
              <Input type="password" className="mt-1" />
            </label> */}
            <label className="block md:col-span-2">
              <Label>
                Email adresi
              </Label>
              <Input
                type="email"
                {...register("email")}
                placeholder="example@example.com"
                className="mt-1"
              />
            </label>


            <label className="block md:col-span-2">
              <Label> Telefon </Label>
              <Input
                type="text"
                {...register("profile.contact")}
                placeholder="+9053529149598"
                className="mt-1"
              />
            </label>

            <label className="block md:col-span-2">
              <Label>Bio</Label>

              <Textarea {...register("profile.bio")} className="mt-1" rows={4} />
              <p className="mt-1 text-sm text-neutral-500">
                Brief description for your article. URLs are hyperlinked.
              </p>
            </label>

            <div className="block md:col-span-2">
              <Label>
                Profil fotoğrafı
              </Label>

              <FileInput name="profile.avatar" control={control} multiple={false}/>

            </div>

            <ButtonPrimary className="md:col-span-2" type="submit">
              {loading ? "Loading..." : "Kaydet"}
            </ButtonPrimary>
          </form>
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardEditProfile;
