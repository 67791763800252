import React, { ChangeEventHandler, FC, ReactNode } from 'react';

export interface CheckboxProps {
    label?: string | ReactNode;
    subLabel?: string;
    name: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Checkbox: FC<CheckboxProps> = ({ subLabel = '', label = '', name, onChange = () => {} }) => {
    return (
        <div className="flex items-start">
            <div className="flex items-center">
                <input
                    onChange={onChange}
                    id={name}
                    name={name}
                    type="checkbox"
                    className="focus:ring-action-[#7646e4] focus:ring-[#7646e4] ring-[#7646e4] h-4 w-4 text-[#7646e4] border-[#7646e4]"
                />
            </div>
            {label && (
                <div className="ml-3 text-sm">
                    <label
                        htmlFor={name}
                        className="text-paragraph-small text-black dark:text-white"
                    >
                        {label}
                    </label>
                    {subLabel && <p className="text-neutral-500">{subLabel}</p>}
                </div>
            )}
        </div>
    );
};

export default Checkbox;
