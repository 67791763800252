import ButtonSecondary from 'components/Button/ButtonSecondary';
import NcImage from 'components/NcImage/NcImage';
import { TaxonomyType } from 'data/types';
import { FC, MouseEventHandler } from 'react';
import { RiArrowRightUpLine } from 'react-icons/ri';

export interface CardCategory1Props {
    className?: string;
    taxonomy?: TaxonomyType;
    size?: 'large' | 'normal';
    image?: any;
    name?: string;
    data?: any;
    subTitle?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

const CardCategory1: FC<CardCategory1Props> = ({
    className = '',
    size = 'normal',
    taxonomy,
    image,
    name,
    data,
    subTitle = '',
    onClick = () => {},
}) => {
    const singleImage = image.map((item: any) => item.thumbnail);
    const url = `${process.env.REACT_APP_START_URL}?category=${data?.slug}`;

    return (
        <div
            className={`nc-CardCategory1 flex items-center cursor-pointer  ${className}`}
            onClick={onClick}
        >
            <NcImage
                alt=""
                containerClassName={`relative flex-shrink-0 ${
                    size === 'large' ? 'w-20 h-20' : 'w-12 h-12'
                } rounded-lg mr-4 overflow-hidden`}
                src={singleImage[0] || ''}
                fill
                className="object-cover"
                sizes="80px"
            />
            <div className="flex items-center justify-between w-full">
                <div>
                    <h2
                        className={`${
                            size === 'large' ? 'text-lg' : 'text-base'
                        } nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
                    >
                        {name}
                    </h2>
                    <span
                        className={`${
                            size === 'large' ? 'text-sm' : 'text-xs'
                        } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
                    >
                        {subTitle}
                    </span>
                </div>
                <ButtonSecondary
                    className="!bg-[#88E64A] !text-black !px-2 sm:!px-4 !py-2 hover:!bg-[#a6fa6e] !text-[2.2vw] sm:!text-[1.7vw] md:!text-[1.4vw] lg:!text-sm !font-semibold"
                    type="button"
                >
                    Toplantı Oluştur
                    <RiArrowRightUpLine size={20} />
                </ButtonSecondary>
            </div>
        </div>
    );
};

export default CardCategory1;
