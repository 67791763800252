import Badge from 'components/Badge/Badge';
import { PostDataType, TwMainColor } from 'data/types';
import { FC } from 'react';

export interface CategoryBadgeListProps {
    className?: string;
    itemClass?: string;
    categories: PostDataType['categories'];
}

const colorList: TwMainColor[] = ['red', 'purple', 'indigo', 'blue'];

const CategoryBadgeList: FC<CategoryBadgeListProps> = ({
    className = 'flex flex-wrap space-x-2',
    itemClass,
    categories,
}) => {
    return (
        <div
            className={`nc-CategoryBadgeList ${className}`}
            data-nc-id="CategoryBadgeList"
        >
            {categories?.map((item: any, index) => {
                const newName = item?.name.includes('_') ? item?.name.split('_')[0].trim() : item?.name;
                return (
                    <Badge
                        className={itemClass}
                        key={index}
                        name={newName}
                        color={item?.color ?? colorList[item?.id % colorList.length]}
                    />
                );
            })}
        </div>
    );
};

export default CategoryBadgeList;
